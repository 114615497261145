import { Card, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from 'react';
import agent from '../../../assets/images/call-center.gif';
import admin from '../../../assets/images/user.gif';
import sup from '../../../assets/images/customer-service.gif';
import agentCommercial from '../../../assets/images/labour-market.gif';
import {fetchUserStatistics} from '../services/api'

function UserCount() {
    const { Title } = Typography;
    const [userStat, setUserStat] = useState({});

    useEffect(() => {
			console.log('calling fetchUserStatistics from user stats')
        fetchUserStatistics(setUserStat);
    }, []);

    
    const count = Object.keys(userStat).length > 0 ? [
        {
            today: "Total des Administrateurs",
            title: userStat.Admin,
            icon: admin,
            color: '#5AB2FF',
        },
        {
            today: "Total des Superviseurs",
            title: userStat.Superviseur,
            icon: sup,
            color: '#FF0000',
        },
        {
            today: "Total des Téléopérateurs",
            title: userStat.Agent,
            icon: agent,
            color: '#00E396',
        },
        {
            today: "Total des Agents Commerciaux",
            title: userStat['Agent Commercial'],
            icon: agentCommercial,
            color: '#FF7F3E',
        },
    ] : [];

    return (
        <>
            <div className="layout-content">
                <Title level={4} style={{ color: "gray" }}>Statistiques des utilisateurs</Title>
                <Row className="rowgap-vbox" gutter={[24, 24]}>
                    {count.map((c, index) => (
                        <Col
                            key={index}
                            xs={24}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="mb-24"
                        >
                            <Card bordered={false} className="circlebox" style={{ height: '160px', backgroundColor: c.backgroundColor, padding: "15px" }}>
                                <div className="number">
                                    <Row align="middle" gutter={[16, 0]}>
                                        <Col xs={18}>
                                            <Title level={5} style={{ fontSize: '14px' }}>
                                                {c.today}
                                            </Title>
                                            <div>
                                                <Title level={4} style={{ margin: '8px 0', color: c.color }}>
                                                    {c.title !== undefined ? c.title : null}
                                                </Title>
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                            <div className="icon-box">
                                                <img src={c.icon} alt={c.today} style={{ width: '40px', marginBottom: '10px' }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
}

export default UserCount;
