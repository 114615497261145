import React, { useState, useEffect } from "react";
import { Button, Modal, Table, DatePicker, Card, ConfigProvider,Spin } from "antd";
import { axiosClient } from "../../../api/axios";
import { EyeOutlined } from "@ant-design/icons";
import AppointmentDetailsCom from "../ACDetails/AppoitmentDet";
import { useCalendar } from "../../../CalendarContext";
import { fetchACRdv, fetchAgentOptions } from "../services/api";
import frFR from "antd/lib/locale/fr_FR";


const DataTable = () => {
	const [detailsModalVisible, setDetailsModalVisible] = useState(false);
	const [selectedRowData, setSelectedRowData] = useState(null);

	const [filtersChanged, setFiltersChanged] = useState(false);
	const [searchText, setSearchText] = useState("");
	const {
		rdvLoading,
		selectedAgent, 
		selectedDateRange, setSelectedDateRange,
	 	setAgentOptions, setUserAgenda,

	} = useCalendar();

	const [rdv, setRdv] = useState([]);

	const [userId, setUserId] = useState("");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const authToken = localStorage.getItem("auth_token");
				if (!authToken) {
					return;
				}

				const response = await axiosClient.get("user", {
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
				});
				setUserId(response.data.id)
			} catch (error) {
				console.error("Error fetching user data:", error);
			}
		};

		fetchUserData();
	}, []);

	useEffect(() => {
		
		if (filtersChanged && userId) {
			fetchACRdv(userId, setUserAgenda, setRdv, selectedAgent, selectedDateRange).then(() => setLoading(false));
			setFiltersChanged(false);
		}
	}, [userId, setUserAgenda, selectedAgent, selectedDateRange,filtersChanged]);


	useEffect(() => {
		if (userId) {
			fetchACRdv(userId, setUserAgenda, setRdv, selectedAgent, selectedDateRange).then(() => setLoading(false));
			fetchAgentOptions(setAgentOptions);

		}

	}, [userId, selectedDateRange,selectedAgent,setAgentOptions,setUserAgenda]);

	const handleDetailsClick = (record) => {
		setSelectedRowData(record);
		setDetailsModalVisible(true);
	};



	const columns = [
		{
			title: "Client",
			dataIndex: "client",
			key: "client",
			width: "25%",
			render: (_, record) => (
					<span style={{ fontSize: '13px' }}>
							{record.nom ? highlightText(record.nom) : "N/A"}
							&nbsp;
					{highlightText(record.prenom)}
					</span>
			),
	}
,	
{
	title: "Status",
	dataIndex: "status",
	key: "status",
	render: (status) => {
		let color = "#EC9D34";
		let underline = "3px solid";

		if (status === "confirmer") {
			color = "#4CD9C4";
		
		} else if (status === "NRP") {
			color = "#FE97AB";
		}

		const capitalizedStatus = status.charAt(0).toUpperCase() + status.slice(1);


		return (
			<>
				<span style={{color, borderBottom: `${underline} ${color}` }}>
					{capitalizedStatus}
				</span>
			</>

		);
	},
},

		{
			title: "Agent",
			dataIndex: "nom",
			key: "nom",
			render: (_, record) => (
				<span style={{ fontSize: '13px' }} >
					{record.user ? highlightText(record.user.nom) : "N/A"}
					&nbsp;
					{record.user ? highlightText(record.user.prenom) : ""}
				</span>
			),
		},

		{
			title: "TEL",
			dataIndex: "tel",
			key: "tel",
			width: "10%",
			className: "agenda-column",
			render: (text) => highlightText(text ? `+32${text}` : "N/A"),
		},
		{
			title: "GSM",
			dataIndex: "gsm",
			key: "gsm",
			width: "10%",
			className: "agenda-column",
			render: (text) => highlightText(text ? `+324${text}` : "N/A"),
		},

		{
			title: "Postal",
			dataIndex: "postal",
			key: "postal",
			width: "10%",
			className: "agenda-column",
			render: (text) => highlightText(text || "N/A"),
		},
		{
			title: "Date",
			dataIndex: "start_date",
			key: "start_date",
			width: "15%",
			className: "agenda-column",
			render: (text) => {
				const formattedDate = text ? new Date(text).toLocaleString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false }) : "-";
				return highlightText(formattedDate);
			},
		}
		,

		{
			title: "Action",
			key: "action",

			render: (_, record) => (
				<div>
					<Button
						type="link"
						onClick={() => handleDetailsClick(record)}
					>
						<EyeOutlined />
					</Button>


				</div>
			),
		},
	];

	const highlightText = (text) => {
		if (!text || !searchText) return text;
		const searchWords = searchText.toLowerCase().split(' ');
		// Escape special characters in the search text and join with '|'
		const escapedSearchWords = searchWords.map(word => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
		const regex = new RegExp(`(${escapedSearchWords.join('|')})`, 'gi');
		return text.toString().split(regex).map((part, index) => {
			return searchWords.includes(part.toLowerCase()) ? (
				<span key={index} style={{ backgroundColor: "#FB6D48", fontWeight: "bold" }}>{part}</span>
			) : (
				part
			);
		});
	};







	const handleDownloadClick = () => {
		const customHeaders = [
			"Status",
			"Client",
			"Agent",
			"Agent Commercial",
			"Professionnel/Residentiel",
			"Nom Ste",
			"TVA",
			"Adresse",
			"Code Postal",
			"TEL",
			"GSM",
			"Nombre de Compteurs Électriques",
			"Nombre de Compteurs Gaz",
			"Commentaire",
			"Fournisseur",
			"PPV",
			"Tarif",
			"Haute Tension",
			"Tarification",
			"Note"
		];
		// Map data fields to custom headers
		const dataFieldMapping = {
			"Status": row => row.status ?? "N/A",
			"Client": row => `${row.nom ?? "N/A"} ${row.prenom ?? ""}`,
			"Agent": row => `${row.agent?.nom ?? "N/A"} ${row.agent?.prenom ?? ""}`,
			"Agent Commercial": row => row.agentCommercial ?? "N/A",
			"Professionnel/Residentiel": row => row.pro == 0 || row.pro == false ? "Residentiel" : "Professionnel",
			"Nom Ste": row => row.nom_ste ?? "N/A",
			"TVA": row => row.tva ? `BE0${row.tva}` : "N/A",
			"Adresse": row => row.adresse ?? "N/A",
			"Code Postal": row => row.postal ?? "N/A",
			"TEL": row => row.tel ? `+32${row.tel}` : "N/A",
			"GSM": row => row.gsm ? `+324${row.gsm}` : "N/A",
			"Nombre de Compteurs Électriques": row => row.nbr_comp_elect ?? "N/A",
			"Nombre de Compteurs Gaz": row => row.nbr_comp_gaz ?? "N/A",
			"Commentaire": row => row.commentaire ?? "N/A",
			"Fournisseur": row => row.fournisseur ?? "N/A",
			"PPV": row => row.ppv == 0 || row.ppv == false ? "non" : "oui",
			"Tarif": row => row.tarif == 0 || row.tarif == false ? "non" : "oui",
			"Haute Tension": row => row.haute_tension == 0 || row.haute_tension == false ? "non" : "oui",
			"Tarification": row => row.tarification ?? "N/A",
			"Note": row => row.note ?? "N/A"
	};

		// Construct header row with custom headers
		const headerRow = customHeaders.map(header => `"${header}"`).join(",");

		// Construct data rows
		const dataRows = rdv.map(row => {
			return customHeaders.map(header => {
				const field = dataFieldMapping[header];
				if (typeof field === "function") {
					return `"${field(row)}"`;
				} else {
					return `"${row[field] || ""}"`;
				}
			}).join(",");
		});

		// Construct CSV content
		const csvContent = "data:text/csv;charset=utf-8," + headerRow + "\n" + dataRows.join("\n");

		// Create a link element
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", "appointments.csv");
		document.body.appendChild(link);

		// Click the link to trigger download
		link.click();
		const formData = new FormData();
		formData.append('csvContent', csvContent);
		axiosClient.post('save-csv', formData)
			.then(response => {
			})
			.catch(error => {
				console.error('Error:', error.response);
			});
	};



	return (
		<div>
			<Card style={{ marginBottom: "20px" }}>
				

				<ConfigProvider locale={frFR}>
					<DatePicker.RangePicker
						style={{ width: "25%" }}

						value={selectedDateRange}
						showTime={{ format: 'DD/MM/YYYY' }}
						disabledDate={(current) => current && (current.day() === 0 || current.day() === 6)}
						onChange={(dates) => {
							setSelectedDateRange(dates)
							setFiltersChanged(true);
						}}

					/>
				</ConfigProvider>



			</Card>


			<Card>

			<Spin spinning={loading || rdvLoading}> {/* Add Spin component */}
                    <Table
                        columns={columns}
                        dataSource={rdv}
                        loading={rdvLoading}
                        pagination={{ pageSize: 5 }}
                        style={{ fontSize: "12px" }}
                    />
                </Spin>
				<Button type="primary" onClick={handleDownloadClick} style={{ marginTop: "10px", width: "100%" }}>
					Télécharger tous les rendez-vous
				</Button>

			</Card>



			<Modal
				title="Consulter rendez-vous"
				open={detailsModalVisible}
				onCancel={() => setDetailsModalVisible(false)}
				footer={null}
				style={{ marginTop: "-50px" }}
				width="80%"
				destroyOnClose
			>
				{selectedRowData && (
					<AppointmentDetailsCom rdv={selectedRowData} />

				)}
			</Modal>

		</div>
	);
};

export default DataTable;