import React, { useState } from 'react';
import { NavLink, useLocation, Redirect } from 'react-router-dom';
import { Menu } from 'antd';
import {
  AppstoreOutlined,
  CalendarOutlined,
  ContactsOutlined,
  TeamOutlined,
  ScheduleOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { useAuth } from '../../AuthContext';
import '../../assets/styles/sidenav.css';

const { SubMenu } = Menu;

const Sidenav = ({ color, collapsed }) => {
  const { pathname } = useLocation();
  const page = pathname.replace('/', '');
  const { userRole } = useAuth();

  if (!localStorage.getItem('auth_token')) {
    return <Redirect to="/login" />;
  }

  // Define menu items based on user role
  const menuItems = {
    superadmin: [
      { key: "calendrier", label: "Calendrier", icon: <CalendarOutlined /> },
      {
        key: "dashboard",
        label: "Dashboard",
        icon: <AppstoreOutlined />,
        subpages: [
          { key: "statistiques-rdv-du-mois", label: "Statistiques rdvs S&M" },
          { key: "statistiques-rdv-pour-demain", label: "Statistiques rdvs du Jour" },
          { key: "historiques-rdv-modifiés", label: "Rendez-vous modifiés" },
          { key: "historique-rdv-supprimé", label: "Rendez-vous supprimés" },
          { key: "historique-utilisateurs-supprimé", label: "Utilisateurs inactifs" },
          { key: "agent-statistiques", label: "Production des agents" },
        ],
      },
      { key: "rdv", label: "Liste de RDV", icon: <ScheduleOutlined /> },
      { key: "agenda", label: "Liste des agendas", icon: <ContactsOutlined /> },
      { key: "creer-utilisateur", label: "Créer utilisateur", icon: <UserAddOutlined /> },
      { key: "utilisateurs", label: "Liste des utilisateurs", icon: <TeamOutlined /> },
      { key: "contact", label: "Liste des commerciaux", icon: <ContactsOutlined /> },
    ],
    Admin: [
      { key: "calendrier", label: "Calendrier", icon: <CalendarOutlined /> },
      {
        key: "dashboard",
        label: "Dashboard",
        icon: <AppstoreOutlined />,
        subpages: [
          { key: "statistiques-rdv-du-mois", label: "Statistiques rdvs S&M" },
          { key: "statistiques-rdv-pour-demain", label: "Statistiques rdvs du Jour" },
          { key: "historiques-rdv-modifiés", label: "Rendez-vous modifiés" },
          { key: "historique-rdv-supprimé", label: "Rendez-vous supprimés" },
          { key: "historique-utilisateurs-supprimé", label: "Utilisateurs inactifs" },
          { key: "agent-statistiques", label: "Production des agents" },
        ],
      },
      { key: "rdv", label: "Liste de RDV", icon: <ScheduleOutlined /> },
      { key: "agenda", label: "Liste des agendas", icon: <ContactsOutlined /> },
      { key: "creer-utilisateur", label: "Créer utilisateur", icon: <UserAddOutlined /> },
      { key: "utilisateurs", label: "Liste des utilisateurs", icon: <TeamOutlined /> },
      { key: "contact", label: "Liste des commerciaux", icon: <ContactsOutlined /> },
    ],
    Superviseur: [
      { key: "calendrier", label: "Calendrier", icon: <CalendarOutlined /> },
      {
        key: "dashboard",
        label: "Dashboard",
        icon: <AppstoreOutlined />,
        subpages: [
          { key: "statistiques-rdv-du-mois", label: "Statistiques rdvs S&M" },
          { key: "statistiques-rdv-pour-demain", label: "Statistiques rdvs du Jour" },
          { key: "historiques-rdv-modifiés", label: "Rendez-vous modifiés" },
        ],
      },
      { key: "rdv", label: "Liste de RDV", icon: <ScheduleOutlined /> },
      { key: "agenda", label: "Liste des agendas", icon: <ContactsOutlined /> },
      { key: "creer-utilisateur", label: "Créer utilisateur", icon: <UserAddOutlined /> },
      { key: "utilisateurs", label: "Liste des utilisateurs", icon: <TeamOutlined /> },
      { key: "contact", label: "Liste des contacts", icon: <ContactsOutlined /> },
    ],
    Agent: [
      { key: "calendrier", label: "Calendrier", icon: <CalendarOutlined /> },
      {
        key: "dashboard",
        label: "Dashboard",
        icon: <AppstoreOutlined />,
        subpages: [
          { key: "statistiques-des-rdvs", label: "Statistiques des RDVS" },
          { key: "agent-rdvs-table", label: "Historiques des RDVS " },
        ],
      },
    ],
    "Agent Commercial": [
      { key: "AC-calendrier", label: "Calendrier", icon: <CalendarOutlined /> },
      { key: "AC-dashboard", label: "Dashboard", icon: <AppstoreOutlined /> },
    ],
  };

  const roleItems = menuItems[userRole];

  // Find the parent key for the selected subpage
  const findParentKey = (key, items) => {
    for (let item of items) {
      if (item.subpages) {
        for (let subitem of item.subpages) {
          if (subitem.key === key) {
            return item.key;
          }
        }
      }
    }
    return null;
  };

  const parentKey = findParentKey(page, roleItems);

  // Default selected keys for Admin role
  const defaultSelectedKeys = userRole === 'Admin' ? ['statistiques-rdv-pour-demain'] : [page];
  const defaultOpenKeys = userRole === 'Admin' ? ['dashboard'] : parentKey ? [parentKey] : [];

  return (
    <div className={`menuContainer ${collapsed ? 'collapsed' : ''}`}>
      <Menu
        mode="inline"
        defaultSelectedKeys={defaultSelectedKeys}
        selectedKeys={[page]}
        defaultOpenKeys={defaultOpenKeys}
        style={{ backgroundColor: 'transparent' }}
        className={collapsed ? 'collapsed-menu' : ''}
      >
        {roleItems &&
          roleItems.map((item) =>
            item.subpages ? (
              <SubMenu key={item.key} icon={item.icon} title={item.label} style={{ backgroundColor: '#61559F' }}>
                {item.subpages.map((subitem) => (
                  <Menu.Item key={subitem.key} style={{ fontSize: "12px", color: "black !important" }}>
                    <NavLink to={`/${subitem.key}`}>{subitem.label}</NavLink>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={item.key} icon={item.icon}>
                <NavLink to={`/${item.key}`}>{item.label}</NavLink>
              </Menu.Item>
            )
          )}
      </Menu>
    </div>
  );
};

export default Sidenav;


