import React, { useEffect, useState, useMemo } from "react";
import { Card, Input, Form, Row, Col, ConfigProvider, DatePicker, message, Alert, Button } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import moment from "moment";
import { axiosClient } from "../../../api/axios";
import { StopOutlined } from "@ant-design/icons";
import { useAuth } from "../../../AuthContext";

const SupBlock = ({ selectedDate, onFormSubmit, showAlert, setShowAlert, setSupBlock }) => {
	const [loading, setLoading] = useState(false);
	const [formKey, setFormKey] = useState(0);
	const [selectedStartDate, setSelectedStartDate] = useState(null);
	const [selectedEndDate, setSelectedEndDate] = useState(null);
	const { userId } = useAuth()


	useEffect(() => {
		resetForm();
		// Set the initial selected date in the DatePicker if it exists
		if (selectedDate && selectedDate.start && selectedDate.end) {
			const initialStartDate = moment(selectedDate.start);
			const initialEndDate = moment(selectedDate.end);
			setSelectedStartDate(initialStartDate);
			setSelectedEndDate(initialEndDate);
			setFormData({
				...formData,
				appointment_date: [
					initialStartDate.toDate(),
					initialEndDate.toDate()
				]
			});
		}
	}, [selectedDate]);




	const [formData, setFormData] = useState({
		postal: "",
		titre: "",
		note: "",
		reserver_plage_horaire: true,
		appointment_date: selectedDate
			? [
				selectedDate.start && new Date(selectedDate.start),
				selectedDate.end && new Date(selectedDate.end),
			]
			: null,
	});

	const resetForm = () => {
		setFormKey((prevKey) => prevKey + 1);
		setSelectedStartDate(null);
		setSelectedEndDate(null);
		setFormData({
			postal: "",
			titre: "",
			reserver_plage_horaire: true,
			note: "",
			appointment_date: null,
		});
	};

	const initialValues = useMemo(() => ({
		postal: "",
		note: "",
		titre: "",
		reserver_plage_horaire: true,
		appointment_date: selectedDate && selectedDate.start
			? [
				new Date(selectedDate.start),
				new Date(selectedDate.end),
			]
			: null,
	}), [selectedDate]);

	const handleBloquerRdv = async () => {
		setLoading(true);

		if (!selectedStartDate || !selectedEndDate) {
			setLoading(false);
			return;
		}

		const startDate = selectedStartDate.toDate();
		const endDate = selectedEndDate.toDate();

		if (startDate && endDate && !isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
			try {
				const startDateFormatted = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
				const endDateFormatted = moment(endDate).format('YYYY-MM-DD HH:mm:ss');


				if (!formData.titre) {
					message.warning("Le titre est obligatoire.");
					setLoading(false);
					return;
				}
				const formDataToSend = {
					...formData,
					reserver_plage_horaire: true,
					start_date: startDateFormatted,
					end_date: endDateFormatted,
					id_agent: userId,
					id_agenda: selectedDate.agendaId,
					bloquer: false,
					rdv_prive: false,

				};
				console.log('formdataToSend reserve rdv', formDataToSend)
				console.log('date', Date.now())

				const response = await axiosClient.post("rdvs/reserver-rdv", formDataToSend);
				const newAppointment = {
					...response.data,
					id: response.data.rdv.id,
				};
				setLoading(false);
				// onFormSubmit({ ...response.data, newAppointment });
				console.log('response reserve rdv', response.data)
				setSupBlock(false)

				message.success("Rendez-vous reservé avec succès !");
				resetForm();
				setShowAlert(false);

			} catch (error) {
				if (error.response && error.response.status == 409) {
					console.error("Error adding appointment:", error);
					resetForm();
					setShowAlert(true);
					setLoading(false);
					return;
				}
				setLoading(false);
				resetForm();
				setShowAlert(false);
				console.error("Error adding appointment:", error);
			}
		} else {
			resetForm();
			setShowAlert(false);
			setLoading(false);
		}
	};
	const disabledDate = (current) => {
		// Disable weekends (Saturday and Sunday)
		if (current && (current.day() === 0 || current.day() === 6)) {
			return true;
		}
	
		// Disable past dates
		return current && current < moment().startOf('day');
	};
	
	const handleStartDateChange = (date) => {
		setSelectedStartDate(date);
	};

	const handleEndDateChange = (date) => {
		setSelectedEndDate(date);
	};

	const disabledDateTime = () => {
		return {
			disabledHours: () => {
				const hours = [];
				for (let i = 0; i < 24; i++) {
					if (i < 9 || i > 19) {
						hours.push(i);
					}
				}
				return hours;
			}
		};
	};
	const disabledTime = (date) => {
		if (date) {
			const hours = Array.from({ length: 24 }, (_, i) => i);
			const disabledHours = hours.filter(hour => hour < 9 || hour > 18);
			return {
				disabledHours: () => disabledHours,
				disabledMinutes: (selectedHour) => selectedHour == 18 ? [15, 30, 45] : [],
			};
		}
		return {};
	};

	return (
		<Form
			onFinish={handleBloquerRdv}
			key={formKey}
			initialValues={initialValues}
		>
			{showAlert && (
				<Alert
					message="Vous avez déjà un rendez-vous à cette date. Veuillez le reporter ou essayer de réserver un autre jour."
					type="warning"
					showIcon
					closable
					onClose={() => setShowAlert(false)}
				/>
			)}
			<Card>
				<Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
					<Col span={9}>
						<ConfigProvider locale={frFR}>
							<DatePicker
								value={selectedStartDate}
								onChange={handleStartDateChange}
								clearIcon={null}
								format="YYYY-MM-DD HH:mm"
								showTime={{ format: "HH:mm", minuteStep: 15 }}
								disabledDate={disabledDate} // Fix: Pass the correct disabledDate function here
								disabledTime={disabledTime} // Ensure this works as intended
							/>

						</ConfigProvider>
					</Col>
					<Col span={9}>
						<ConfigProvider locale={frFR}>
							<DatePicker
								value={selectedEndDate}
								onChange={handleEndDateChange}
								clearIcon={null}
								format="YYYY-MM-DD HH:mm"
								showTime={{ format: "HH:mm", minuteStep: 15 }}
								disabledDate={disabledDate}
								disabledTime={disabledTime}



							/>
						</ConfigProvider>
					</Col>
					<Col span={6}>
						<Button
							icon={<StopOutlined />}
							loading={loading}
							onClick={handleBloquerRdv}
							style={{ border: "1px solid #FC6736", color: "#FC6736" }}
							className="block-btn"
						>
							Reserver
						</Button>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<p> <span style={{ color: 'red' }}>* </span> Entrez le titre:</p>
						<Form.Item name="titre" rules={[{ required: true, message: 'Veuillez entrer un titre' }]}>
							<Input
								placeholder="Titre"
								onChange={(e) => {
									const { value } = e.target;
									setFormData({
										...formData,
										titre: value,
									});
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<p> Entrez le code postal :</p>
						<Form.Item name="postal" rules={[{ required: true, message: 'Veuillez entrer un code postal' }]}>
							<Input
								placeholder="Code Postal"
								onChange={(e) => {
									const { value } = e.target;
									setFormData({
										...formData,
										postal: value,
									});
								}}
							/>
						</Form.Item>
					</Col>

				</Row>

			</Card>
		</Form>
	);
};

export default SupBlock;
