import React, { useState, useEffect } from "react";
import { Button, Modal, Table, message, Select, DatePicker, Card, ConfigProvider, Input, Row, Col, Tag } from "antd";
import { pencil, deletebtn } from "../../../constants/icons";
import { axiosClient } from "../../../api/axios";
import { EyeOutlined } from "@ant-design/icons";
import RdvDetail from "../Store/RdvDetail";
import SearchInput from "../../../constants/SearchInput";
import { useCalendar } from "../../../CalendarContext";
import { fetchRdv, fetchAgentOptions, fetchAgendaOptions } from "../services/api";
import frFR from "antd/lib/locale/fr_FR";
import UpdateRdv from "../updateRdv/RdvUpdate";
import { DownloadOutlined } from '@ant-design/icons';
import { useAuth } from "../../../AuthContext";
const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input
const DataTable = () => {
	const [updateModalVisible, setUpdateModalVisible] = useState(false);
	const [detailsModalVisible, setDetailsModalVisible] = useState(false);
	const [selectedRowData, setSelectedRowData] = useState(null);
	const [searchedColumn, setSearchedColumn] = useState('');
	const [tableData, setTableData] = useState({
		appointments: [],
		appointmentsByDay: 0,
		appointmentsByWeek: 0,
		appointmentsByMonth: 0,
		totalAppointments: 0,
	});
	const [filtersChanged, setFiltersChanged] = useState(false);
	const [searchText, setSearchText] = useState("");
	const {
		rdvLoading, setRdvLoading,
		selectedAgent, setSelectedAgent,
		selectedAgenda, setSelectedAgenda,
		selectedDateRange, setSelectedDateRange,
		agentOptions, setAgentOptions,
		agendaOptions, setAgendaOptions,
	} = useCalendar();
	const { userId, userRole } = useAuth()
	useEffect(() => {
		fetchRdv(selectedAgent, selectedAgenda, selectedDateRange, setTableData, setRdvLoading,);
		fetchAgentOptions(setAgentOptions);
		fetchAgendaOptions(setAgendaOptions);
	}, []);

	useEffect(() => {
		if (filtersChanged) {
			fetchRdv(selectedAgent, selectedAgenda, selectedDateRange, setTableData, setRdvLoading,);
			setFiltersChanged(false);
		}
	}, [filtersChanged]);
	useEffect(() => {
		return () => {
			setSelectedAgent([]);
			setSelectedAgenda([]);
			setSelectedDateRange(null);
		};
	}, []);

	const handleUpdateClick = (record) => {
		setSelectedRowData(record);
		setUpdateModalVisible(true);
	};

	const handleDetailsClick = (record) => {
		setSelectedRowData(record);
		setDetailsModalVisible(true);
	};
	const handleUpdateFormSubmit = (updatedData) => {


		const updatedAppointments = tableData.appointmentsWithAgentsAndCommercials.map((item) => {
			if (item.key == selectedRowData.key) {
				return {
					...item,
					...updatedData,
				};
			}
			return item;
		});
		setTableData({ ...tableData, appointments: updatedAppointments });
		setUpdateModalVisible(false);
	};

	const deleteRecord = async (reason, record) => {
		try {
			if (!reason) {
				Modal.warning({
					title: "Suppression impossible",
					content: "Veuillez entrer une raison pour la suppression."
				});
				return;
			}
			await axiosClient.delete(`rdvs/${record.id}`, {
				data: {
					reason,
					userId
				}
			});
			fetchRdv(selectedAgent, selectedAgenda, selectedDateRange, setTableData, setRdvLoading,);
			message.success('Rendez-vous supprimé avec succès');
		} catch (error) {
			console.error('Erreur lors de la suppression du rendez-vous :', error);
			message.error('Erreur lors de la suppression du rendez-vous');
		}
	};

	const showConfirmModal = (record) => {
		let tempReason = "";
		confirm({
			title: 'Confirmer annulation',
			content: (
				<div>
					<p>Êtes-vous sûr de vouloir annuler ce rendez-vous ? Cette action supprimera définitivement le rendez-vous.</p>
					<p>Raison de l'annulation :</p>
					<TextArea rows={4} onChange={(e) => tempReason = e.target.value} />
				</div>
			),
			onOk() {
				deleteRecord(tempReason, record);
			},
			onCancel() {
			},
		});
	};

	const getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, }) => (
			<div style={{ padding: 8 }}>
				<Input.Search
					placeholder={`Recherche ${dataIndex}`}
					onSearch={value => {
						setSelectedKeys([value]);
						confirm();
					}}
					onInput={e => setSearchText(e.target.value)}
					enterButton
					value={selectedKeys[0]}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
					id="searchInput"
					allowClear
				/>
			</div>

		),
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
				: '',
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => document.getElementById('searchInput').select(), 100);
			}
		},
		render: text =>
			searchedColumn == dataIndex ? (
				<span>
					{text.split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')).map((fragment, i) => (
						fragment.toLowerCase() == searchText.toLowerCase()
							? <span key={i} className="highlight">{fragment}</span> : fragment
					))}
				</span>
			) : (
				text
			),
	});

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
			width: "10%",
			render: (text) => <span>{text}</span>,
		},
		{
			title: "Client",
			dataIndex: "client",
			key: "client",
			width: "25%",

			render: (_, record) => (
				<span style={{ fontSize: '13px' }}>
					{highlightText(record.nom)}
					&nbsp;
					{highlightText(record.prenom)}
				</span>
			),
		},
		{
			title: "Agent",
			dataIndex: "agent",
			key: "agent",
			render: (_, record) => (
				<span style={{ fontSize: '13px' }} >
					{record.agent ? record.agent.nom : "N/A"}
					&nbsp;
					{record.agent ? record.agent.prenom : ""}
				</span>
			),
			// ...getColumnSearchProps('agent'),

		},
		{
			title: "Agenda",
			dataIndex: "agentCommercial",

			key: "agentCommercial",
			width: "10%",
			className: "agenda-column"
		},
		{
			title: 'Statut',
			dataIndex: 'status',
			key: 'status',
			render: (status, record) => {
				// Check if the appointment has been deleted (deleted_at exists)
				if (record.deleted_at) {
					return (
						<Tag
							color="#FF4D4F"
							style={{ fontWeight: '400', fontSize: '12px', lineHeight: '1.5', marginBottom: '0' }}
						>
							Annuler
						</Tag>
					);
				}

				// If not deleted, render the usual status
				return (
					<Tag
						color={
							status === 'NRP' ? '#FF8A08' :
								status === 'confirmer' ? '#00E396' :
									status === 'encours' ? '#373A40' :
										'#FFF'
						}
						style={{ fontWeight: '400', fontSize: '12px', lineHeight: '1.5', marginBottom: '0' }}
					>
						{status}
					</Tag>
				);
			},
		},

		{
			title: "TEL",
			dataIndex: "tel",
			key: "tel",
			width: "10%",
			className: "agenda-column",
			...getColumnSearchProps('tel'),
			render: (text) => highlightText(text ? `+32${text}` : "N/A"),
		},
		{
			title: "GSM",
			dataIndex: "gsm",
			key: "gsm",
			width: "10%",
			className: "agenda-column",
			render: (text) => (
				<span style={{ fontSize: '13px' }} >
					{text ? `+32422${text}` : "N/A"}
				</span>
			),
			...getColumnSearchProps('gsm'),
		},

		{
			title: "Postal",
			dataIndex: "postal",
			key: "postal",
			width: "10%",
			className: "agenda-column",
			render: (text) => highlightText(text || "N/A"),
			...getColumnSearchProps('postal'),

		},
		{
			title: "Date",
			dataIndex: "start_date",
			key: "start_date",
			width: "15%",
			className: "agenda-column",
			render: (text) => {
				const formattedDate = text ? new Date(text).toLocaleString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false }) : "-";
				const parts = formattedDate.split(', ');
				if (parts.length == 2) {
					return (
						<div className="date-column">
							{highlightText(parts[0])}
							<br />
							{highlightText(parts[1])}
						</div>
					);
				} else {
					return highlightText(formattedDate);
				}
			},
		}
		,
		{
			title: "Action",
			key: "action",

			render: (_, record) => (
				<div>
					<Button
						type="link"
						onClick={() => handleDetailsClick(record)}
					>
						<EyeOutlined />
					</Button>
					<Button
						type="link"
						onClick={() => handleUpdateClick(record)}
					>
						{pencil}
					</Button>

					{userRole === 'admin' && (
						<Button
							type="link"
							onClick={() => showConfirmModal(record)}
						>
							{deletebtn}
						</Button>
					)}

				</div>
			),
		},
	];

	const highlightText = (text) => {
		if (!text || !searchText) return text;
		const searchWords = searchText.toLowerCase().split(' ');
		const escapedSearchWords = searchWords.map(word => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
		const regex = new RegExp(`(${escapedSearchWords.join('|')})`, 'gi');
		return text.toString().split(regex).map((part, index) => {
			return searchWords.includes(part.toLowerCase()) ? (
				<span key={index} style={{ backgroundColor: "#FB6D48", fontWeight: "bold" }}>{part}</span>
			) : (
				part
			);
		});
	};

	const handleDownloadClick = () => {
		const customHeaders = [
			"Status",
			"Date",
			"Client",
			"Agent",
			"Agent Commercial",
			"Type",
			"Nom Ste",
			"TVA",
			"Adresse",
			"Code Postal",
			"TEL",
			"GSM",
			"Nombre de Compteurs Électriques",
			"Nombre de Compteurs Gaz",
			"Commentaire",
			"Fournisseur",
			"PPV",
			"Tarif Social",
			"Haute Tension",
			"Tarification",
			"Note"
		];

		const dataFieldMapping = {
			"Status": "status",
			"Date": "start_date",
			"Client": row => `"${row.nom} ${row.prenom}"`,
			"Agent": row => `${row.agent.nom} ${row.agent.prenom}`,
			"Agent Commercial": "agentCommercial",
			"Professionnel/Residentiel": row => row.pro == 0 || row.pro == false ? "Residentiel" : "Professionnel",
			"Nom Ste": "nom_ste",
			"TVA": row => row.tva ? `BE0${row.tva}` : "",
			"Adresse": "adresse",
			"Code Postal": "postal",
			"TEL": row => row.tel ? `"+32${row.tel}"` : "",
			"GSM": row => row.gsm ? `"+324${row.gsm}"` : "",
			"Nombre de Compteurs Électriques": "nbr_comp_elect",
			"Nombre de Compteurs Gaz": "nbr_comp_gaz",
			"Commentaire": "commentaire",
			"Fournisseur": "fournisseur",
			"PPV": row => row.ppv == 0 || row.ppv == false ? "non" : "oui",
			"Tarif Social": row => row.tarif == 0 || row.tarif == false ? "non" : "oui",
			"Haute Tension": row => row.haute_tension == 0 || row.haute_tension == false ? "non" : "oui",
			"Tarification": "tarification",
			"Note": "note"
		};

		const headerRow = customHeaders.map(header => `"${header}"`).join(",");

		const dataRows = tableData.appointmentsWithAgentsAndCommercials.map(row => {
			return customHeaders.map(header => {
				const field = dataFieldMapping[header];
				if (typeof field === "function") {
					return `"${field(row)}"`;
				} else {
					return `"${row[field] || ""}"`;
				}
			}).join(",");
		});

		const csvContent = "data:text/csv;charset=utf-8," + headerRow + "\n" + dataRows.join("\n");

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", "appointments.csv");
		document.body.appendChild(link);

		link.click();
		const formData = new FormData();
		formData.append('csvContent', csvContent);
		axiosClient.post('save-csv', formData)
			.then(response => {
			})
			.catch(error => {
				console.error('Error:', error.response);
			});
	};

	return (
		<div >
			<Card style={{ marginBottom: "20px" }}>
				<Row gutter={[16, 16]} align="middle">
					<Col lg={5} xs={24} md={8} style={{ verticalAlign: "middle" }}>
						<Select
							style={{ width: 200 }}
							mode="multiple"
							placeholder="Sélectionner un Agent"
							onChange={(value) => {
								setSelectedAgent(value);
								setFiltersChanged(true);
							}}
							allowClear
							value={selectedAgent}
							showSearch
							filterOption={(input, option) =>
								(option?.children ?? '').toLowerCase().includes(input.toLowerCase())
							}
						>
							{agentOptions.map(agent => (
								<Option key={agent.id} value={agent.id}>{`${agent.nom} ${agent.prenom}`}</Option>
							))}
						</Select>
					</Col>
					<Col lg={5} xs={24} md={8} style={{ verticalAlign: "middle" }}>
						<Select
							style={{ width: 200 }}
							placeholder="Sélectionner un Agenda"
							onChange={(value) => {
								setSelectedAgenda(value);
								setFiltersChanged(true);
							}}
							allowClear
							value={selectedAgenda}
							mode="multiple"
							showSearch
							filterOption={(input, option) =>
								(option?.children ?? '').toLowerCase().includes(input.toLowerCase())
							}
						>
							{agendaOptions.map(agenda => (
								<Option key={agenda.id} value={agenda.id}>
									{`${agenda.contact_nom} ${agenda.contact_prenom}`}
								</Option>
							))}
						</Select>
					</Col>
					<Col lg={5} xs={24} md={8} style={{ verticalAlign: "middle" }}>
						<ConfigProvider locale={frFR}>
							<DatePicker.RangePicker
								style={{ width: 300 }}
								format="DD/MM/YYYY"
								value={selectedDateRange}
								showTime={{ format: 'DD/MM/YYYY' }}
								disabledDate={(current) => current && (current.day() == 0 || current.day() == 6)}
								onChange={(dates) => {
									setSelectedDateRange(dates);
									setFiltersChanged(true);
								}}
							/>
						</ConfigProvider>
					</Col>
					{userRole === 'admin' && (
						<Col lg={4} xs={24} md={24} style={{ verticalAlign: "middle" }} offset={5} >
							<Button type="primary" onClick={handleDownloadClick} style={{ width: "100%" }} icon={<DownloadOutlined />}>

								Télécharger
							</Button>
						</Col>
					)}

				</Row>
			</Card>
			<Card>
				<SearchInput onChange={(value) => setSearchText(value)} />
				<Table
					className="table-container"
					columns={columns}
					dataSource={tableData.appointmentsWithAgentsAndCommercials}
					loading={rdvLoading}
					pagination={{ pageSize: 5 }}
					style={{ fontSize: "12px" }}
				/>
			</Card>
			<Modal
				title="Modifie rendez-vous"
				visible={updateModalVisible}
				onCancel={() => setUpdateModalVisible(false)}
				footer={null}
				destroyOnClose
				width="80%"
			>
				<UpdateRdv initialValues={selectedRowData} onFormSubmit={handleUpdateFormSubmit}
				/>
			</Modal>
			<Modal
				title="Consulter rendez-vous"
				visible={detailsModalVisible}
				onCancel={() => setDetailsModalVisible(false)}
				footer={null}
				style={{ marginTop: "-50px" }}
				width="80%"
				destroyOnClose
			>
				{selectedRowData && (
					<RdvDetail
						rdv={selectedRowData}
					/>
				)}
			</Modal>
		</div>
	);
};

export default DataTable;