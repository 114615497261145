import React, { useEffect, useState, useRef } from "react";
import { Card, Col, Row, Space, Typography, Tag, Modal, Button, Spin, message, Input, } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import "../../rdv/views/rdv.css";
import ModifierButton from "../../../constants/ModifierButton";
import SupprimerButton from "../../../constants/SupprimerButton";
import DupliquerBtn from "../../../constants/DupliquerBtn";
import { useCalendar } from "../../../CalendarContext";
import { CheckCircleOutlined, CloseCircleOutlined, RetweetOutlined } from "@ant-design/icons";
import {
	fetchAgendasAndAppointments,
} from "../../caledrier/services/api";
import ReporterRdv from "../../rdv/reporterRdv/RdvReporter";
import DuplicateRdv from "../../rdv/duplicateRdv/RdvDuplicate";
import { axiosClient } from "../../../api/axios";
import UpdateRdv from "../../rdv/updateRdv/RdvUpdate";
import { useAuth } from "../../../AuthContext";
const { Text } = Typography;
const { TextArea } = Input

const RdvDetail = () => {
	// const userContext = useUser();
	const { setShowDetailModal, setShowUpdateModal, setShowDuplicateModal, setAppointments, appointments, setDeletedAppointmentIds, agentId, agendaId, showDuplicate, setAgendas, showUpdateModal, contactName,
	} = useCalendar()
	const { userId, userRole } = useAuth()
	const handleRollback = () => {
		setShowDetailModal(false);
	};
	const history = useHistory();
	const [cardHeight, setCardHeight] = useState("auto");
	const clientInfoCardRef = useRef(null);
	const appointmentInfoCardRef = useRef(null);
	const notesCardRef = useRef(null);
	const [rdv, setRdv] = useState(null);
	const [loading, setLoading] = useState(true);
	const [loadingValidation, setLoadingValidation] = useState(false);
	const [reporterModalVisible, setReporterModalVisible] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);

	const handleOpenReporterModal = () => {
		setReporterModalVisible(true);
	};

	const handleCloseReporterModal = () => {
		setReporterModalVisible(false);
	};

	const { id } = useParams();
	const updateCardHeight = () => {
		const clientInfoCardHeight = clientInfoCardRef?.current?.offsetHeight;
		const appointmentInfoCardHeight = appointmentInfoCardRef?.current?.offsetHeight;
		const notesCardHeight = notesCardRef?.current?.offsetHeight;
		const maxHeight = Math.max(clientInfoCardHeight, appointmentInfoCardHeight, notesCardHeight);
		setCardHeight(maxHeight + "px");
	};

	const handleGoBack = () => {
		history.goBack();
	};

	const fetchData = async () => {
		try {
			const response = await axiosClient.get(`rdvs/${id}`);
			setRdv(response.data);
			setLoading(false);
		} catch (error) {
			console.error('Error fetching appointment details:', error);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
		console.log('cc')
	}, [id]);

	useEffect(() => {
		updateCardHeight();
	}, [rdv]);

	if (loading) {
		return (
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
				<Spin tip="Loading..." size="large" />
			</div>
		);
	}
	if (!rdv) {
		return <div>No appointment data available</div>
	}

	const { confirm } = Modal;

	const { start_date, end_date, note, commentaire, nom, prenom, pro, status, nom_ste, tva, tel, gsm, adresse, postal, fournisseur, nbr_comp_elect, nbr_comp_gaz, ppv, tarif, tarification, haute_tension, status_ac, porte } = rdv.rdv || {};
	const agentName = `${rdv.agent.nom} ${rdv.agent.prenom}`;
	const agenda = `${rdv.agentComm.nom} ${rdv.agentComm.prenom}`;

	let statusColor = "#FF8A08";
	const clientType = pro == 1 ? "Professionnel" : "Residential";
	switch (status) {
		case "encours":
			statusColor = "#373A40";
			break;
		case "confirmer":
			statusColor = "#00E396";
			break;
		case "annuler":
			statusColor = "red";
			break;
		default:
			break;
	}
	const clientTypeColor = pro == 1 ? "geekblue" : "gold";

	const formatDate = (date, includeDate = true) => {
		if (!date) return "-";
		const formattedTime = new Date(date).toLocaleTimeString('fr-FR', { hour: 'numeric', minute: 'numeric', hour12: false });
		const formattedDate = includeDate ? new Date(date).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }) : '';
		return `${includeDate ? formattedDate + ' à ' : ''}${formattedTime}`;
	};
	const handleUpdateClick = () => {
		setShowUpdateModal(true);
	};
	const handleDuplicateClick = () => {
		setShowDuplicateModal(true);
	};

	const handleDeleteClick = async (reason) => {
		try {
			if (!reason) {
				Modal.warning({
					title: "Suppression impossible",
					content: "Veuillez entrer une raison pour la suppression."
				});
				return;
			}
			const res = await axiosClient.delete(`rdvs/${id}`, {
				data: {
					reason,
					userId
				}
			});
			const updatedAppointments = appointments.filter(
				(appointment) => appointment.id !== id
			);
			setAppointments(updatedAppointments);
			setDeletedAppointmentIds((prevIds) => [...prevIds, id]);
			setShowDetailModal(false);
			history.goBack();
			message.success('Rendez-vous supprimé avec succès');
			console.log('res delte', res.data)
		} catch (error) {
			console.error('Erreur lors de la suppression du rendez-vous :', error.response);
			message.error('Erreur lors de la suppression du rendez-vous');
		}
	};

	const showConfirmModal = () => {
		let tempReason = "";
		confirm({
			title: 'Confirmer annulation',
			content: (
				<div>
					<p>Êtes-vous sûr de vouloir annuler ce rendez-vous ? Cette action supprimera définitivement le rendez-vous.</p>
					<p>Raison de l'annulation :</p>
					<TextArea rows={4} onChange={(e) => tempReason = e.target.value} />
				</div>
			),
			onOk() {
				handleDeleteClick(tempReason);
			},
			onCancel() {
			},
		});
	};

	const handleFormSubmitCallback = async (newAppointment) => {
		try {
			setAppointments(prevAppointments => [...prevAppointments, newAppointment]);
			setShowUpdateModal(false);
			await fetchAgendasAndAppointments(
				setAgendas,
				setAppointments,
			);
			await fetchData()
		} catch (error) {
			console.error("Error handling form submit:", error);
		}
	};

	const handleValidation = async (status) => {
		if (isProcessing) return;
		setIsProcessing(true);
		setLoadingValidation(true);
	
		// Send only the necessary fields
		const formDataToSend = {
			status: status,
			modifiedBy: userId,
		};
		console.log('formDataToSend', formDataToSend);
	
		try {
			const response = await axiosClient.put(
				`rdvs/valider/${id}`, 
				formDataToSend
			);
	
			// Log the response after validation
			const newAppointment = {
				...response.data.rdv,
				bloquer: false,
			};
			console.log('response', response.data);
	
			setLoadingValidation(false);
			Modal.destroyAll();
			await handleFormSubmitCallback(newAppointment);
			message.success(
				`Rendez-vous ${status === 'confirmer' ? 'confirmé' : 'enregistré comme non répondu'} avec succès!`
			);
		} catch (error) {
			setLoadingValidation(false);
			console.error('Error validating appointment:', error);
		} finally {
			setIsProcessing(false);
		}
	};
	


	return (
		<Card>

			<Card style={{ marginBottom: "10px" }}>
				<Row gutter={[16, 0]} justify={userRole === "Agent" ? "end" : "center"} >
					<Col>
						<Button onClick={handleGoBack}>
							Retour
						</Button>
					</Col>
					{(userRole === "Admin" || userRole === "Superviseur" || userRole === "superadmin" ||
						agentId == userId) && (
							<>
								<Col  >
									<ModifierButton
										buttonText="Modifier"
										onClick={() => {
											handleUpdateClick(id)
										}}
										// onClick={() => {
										// 	if (status === 'encours') {
										// 		handleUpdateClick(id)
										// 	} else {
										// 		Modal.info({
										// 			title: 'Information',
										// 			content: (
										// 				<div>
										// 					<p>Vous ne pouvez rien faire avec ce rendez-vous car il a déjà été statué.</p>
										// 				</div>
										// 			),
										// 			okText: 'OK',
										// 		});
										// 	}
										// }}
									/>
								</Col>
								{(userRole === "Admin" || userRole === "Superviseur" || userRole === "superadmin") && (
									<>
										<Col>
											<Button
												type="primary"
												className="save-button"
												// onClick={() => {
												// 	if (status === 'encours') {
												// 		handleValidation('confirmer');
												// 	}
												// }}
												onClick={() => {
													if (status === 'encours') {
														handleValidation('confirmer');
													} else {
														Modal.info({
															title: 'Information',
															content: (
																<div>
																	<p>Vous ne pouvez rien faire avec ce rendez-vous car il a déjà été statué.</p>
																</div>
															),
															okText: 'OK',
														});
													}
												}}
											>
												<CheckCircleOutlined />
												Confirmer
											</Button>
										</Col>
										<Col>
											<Button
												className="nrp-button"
												type="primary"
												// onClick={() => {
												// 	if (status === 'encours') {
												// 		handleValidation('NRP');
												// 	}
												// }}
												onClick={() => {
													if (status === 'encours') {
														handleValidation('NRP');
													} else {
														Modal.info({
															title: 'Information',
															content: (
																<div>
																	<p>Vous ne pouvez rien faire avec ce rendez-vous car il a déjà été statué.</p>
																</div>
															),
															okText: 'OK',
														});
													}
												}}
											>
												<CloseCircleOutlined />
												NRP
											</Button>
										</Col>
										<Col>
											<Button
												type="primary"
												className="reporter"
												onClick={() => {
													handleOpenReporterModal()
												}}
												// onClick={() => {
												// 	if (status === 'encours') {
												// 		handleOpenReporterModal()
												// 	} else {
												// 		Modal.info({
												// 			title: 'Information',
												// 			content: (
												// 				<div>
												// 					<p>Vous ne pouvez rien faire avec ce rendez-vous car il a déjà été statué.</p>
												// 				</div>
												// 			),
												// 			okText: 'OK',
												// 		});
												// 	}
												// }}
											>
												<RetweetOutlined />
												Reporter
											</Button>
										</Col>
										<Col>
											<SupprimerButton
												buttonText="Annuler RDV"
												onClick={() => {
													showConfirmModal(id)
												}}
												// onClick={() => {
												// 	if (status === 'encours') {
												// 		showConfirmModal(id)
												// 	} else {
												// 		Modal.info({
												// 			title: 'Information',
												// 			content: (
												// 				<div>
												// 					<p>Vous ne pouvez rien faire avec ce rendez-vous car il a déjà été statué.</p>
												// 				</div>
												// 			),
												// 			okText: 'OK',
												// 		});
												// 	}
												// }}
											/>
										</Col>
										<Col  >
											<DupliquerBtn
												buttonText="Dupliquer"
												onClick={() => {
													handleDuplicateClick(id)
												}}
											/>
										</Col>
									</>
								)}
							</>
						)}

				</Row>
			</Card>

			{rdv && (
				<>
					<Card>
						<Row justify="space-between" align="middle" style={{ marginTop: "10px" }}>
							<Col >
								<Text strong>Date du rendez-vous : </Text>
								<Text>{formatDate(start_date)} - {formatDate(end_date, false)}</Text>
							</Col>
							<Col >
								<Tag color={statusColor}>
									RDV : {status_ac ? `${status_ac} - ${status}` : status}
								</Tag>
							</Col>
						</Row>
					</Card>

					<Row gutter={[16, 16]} >
						<Col sm={24} lg={9} md={24}>
							<Card size="small" className="carddisplayrdv" ref={clientInfoCardRef} style={{ height: cardHeight }}>
								<Row >
									<Col span={24}>
										<p><strong>Agent :</strong> {agentName}</p>
									</Col>
									<Col span={24}>
										<p><strong>Agenda :</strong> {agenda.toUpperCase()}</p>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<p><strong>Client :</strong> {rdv && nom && nom.toLowerCase() !== 'null' ? `${nom} ${prenom && prenom.toLowerCase() !== 'null' ? prenom : ''}` : 'N/A'}</p>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<p><strong>Tél :</strong> +32 {tel}</p>
									</Col>
									<Col span={24}>
										<p><strong>GSM :</strong>{gsm ? `+324 ${gsm}` : ' N/A'}</p>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<p><strong>Rue :</strong> {adresse ? adresse : 'N/A'}</p>
									</Col>
									<Col span={24}>
										<p><strong>Numéro :</strong>{porte ? porte : ' N/A'}</p>
									</Col>
									<Col span={24} >
										<p> <strong>Code Postal :</strong> {postal}</p>
									</Col>
								</Row>
								{clientType === "Professionnel" && (
									<Row>
										<Col span={24}>
											<p><strong>Société :</strong> {nom_ste ? nom_ste : "N/A"}</p>
										</Col>
										<Col span={24}>
											<p><strong>TVA :</strong>{tva ? `BE0${tva}` : "N/A"}</p>
										</Col>
									</Row>
								)}

							</Card>
						</Col>
						<Col sm={24} lg={15} md={24}>
							<Card size="small" className="carddisplayrdv" ref={appointmentInfoCardRef} style={{ height: cardHeight }}>
								<Row>
									<Col span={6} offset={18}>
										<Tag color={clientTypeColor}><strong>Type :</strong> {clientType}</Tag>
									</Col>
									<Col span={24}>
										<p><strong>Fournisseur :</strong> {fournisseur}</p>
									</Col>
								</Row>
								<Row>
									<Col span={12}>
										<p><strong>Nombre de compteurs électriques :</strong> {nbr_comp_elect}</p>
									</Col>
									<Col span={12}>
										<p><strong>Nombre de compteurs gazs :</strong> {nbr_comp_gaz}</p>
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col span={24}>
										{commentaire ? (
											<Card title="Commentaire" size="small" bordered={false} className="carddisplayrdvnote" style={{ backgroundColor: "#E6F4FF" }}>
												<p>{commentaire}</p>
											</Card>
										) : (
											<Card title="Commentaire" size="small" bordered={false} className="carddisplayrdvnote" style={{ backgroundColor: "#FFFAB7" }}>
												<p>Aucun commentaire</p>
											</Card>
										)}
									</Col>
								</Row>

								<Row gutter={[16, 16]}>
									<Col xs={24} md={12}>
										<Space align="baseline">
											<Text strong>PPV :</Text>
											{ppv == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
										</Space>
									</Col>
									<Col xs={24} md={12}>
										<Space align="baseline">
											<Text strong>Tarif Social :</Text>
											{tarif == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
										</Space>
									</Col>
									<Col xs={24} md={12}>
										<Space align="baseline">
											<Text strong>Haute Tension :</Text>
											{haute_tension == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
										</Space>
									</Col>
									<Col xs={24} md={12}>
										<Space align="baseline">
											<Text strong>Tarification :</Text>
											<Text type={tarification === "fixe" ? "default" : tarification === "Variable" ? "success" : "default"}>{tarification}</Text>
										</Space>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col span={24}>
							{note ? (
								<Card title="Notes" size="small" bordered={false} className="carddisplayrdvnote" ref={notesCardRef} style={{ backgroundColor: "#FFF2F0" }}>
									<p>{note}</p>
								</Card>
							) : (
								<Card title="Notes" size="small" bordered={false} className="carddisplayrdvnote" ref={notesCardRef} style={{ backgroundColor: "#FFF2F0" }}>
									<p>Aucune note</p>
								</Card>
							)}
						</Col>
					</Row>
				</>
			)}
			<Modal
				open={showDuplicate}
				title="Dupliquer RDV"
				onCancel={() => setShowDuplicateModal(false)}
				footer={null}
				style={{ marginTop: "-50px" }}
				width={500}
				bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
				destroyOnClose
			>
				<>
					<DuplicateRdv
						initialValues={rdv}
						agendaId={agendaId}
						agentId={agentId}
						onFormSubmit={handleFormSubmitCallback}
						handleRollback={handleRollback}
						setShowDuplicateModal={setShowDuplicateModal}
					/>
				</>
			</Modal>
			<Modal
				open={showUpdateModal}
				title={
					<Row justify="space-between" align="middle">
						<Col>
							<p style={{ fontSize: "16px" }}>
								Modifier rendez-vous : {contactName}
							</p>
						</Col>
					</Row>
				}
				onCancel={() => setShowUpdateModal(false)}
				footer={null}
				width={1000}
			>
				<>
					<UpdateRdv
						initialValues={rdv}
						agendaId={agendaId}
						agentId={agentId}
						setShowUpdateModal={setShowUpdateModal}
						onFormSubmit={handleFormSubmitCallback}
						handleRollback={handleRollback}
					/>
				</>
			</Modal>
			<Modal
				open={reporterModalVisible}
				title="Reporter RDV"
				onCancel={handleCloseReporterModal}
				width={500}
				footer={null}
			>
				<ReporterRdv
					initialValues={rdv}
					agendaId={agendaId}
					agentId={agentId}
					onFormSubmit={handleFormSubmitCallback}
					handleRollback={handleRollback}
					setReporterModalVisible={setReporterModalVisible}
				/>
			</Modal>
		</Card>
	);
};

export default RdvDetail;

