/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Modal, Card, Spin, Button, Col, Row, Empty } from "antd";
import AddAppointment from "../addRdv/addrdv.js";
import NewButton from "../../../constants/NewButton";
import { axiosClient } from "../../../api/axios";
import { useCalendar } from "../../../CalendarContext";
import ContactList from "../../contacts/contactList/ContactList.js";
import {
	fetchAgendasAndAppointments,
	handleAppointmentClick,
} from "../services/api";
import UpdateSupBlock from "../updateSupBlock/UpdateSupBlock";
import { StopOutlined } from "@ant-design/icons";
import BlockRdv from "../blockRdv/BlockRdv.js";
import CountdownTimer from "../countdown/CountdownTimer .js";
import SupBlock from "../supBlock/SupBlock.js";
import { useHistory } from "react-router-dom";
import SupprimerButton from "../../../constants/SupprimerButton.js";
import ModifierButton from "../../../constants/ModifierButton.js";
import UpdateRdv from "../../rdv/updateRdv/RdvUpdate.js";
import { useAuth } from "../../../AuthContext.js";
import angry from '../../../assets/images/angry-review.gif'
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { pusherKey } from "../../../api/axios";

window.Pusher = Pusher;

window.Echo = new Echo({
	broadcaster: 'pusher',
	key: pusherKey,
	cluster: 'eu',
	encrypted: true,
});


function Calendar() {
	const {
		showAddModal, setShowAddModal, appointments, setAppointments, selectedItems, setSelectedItems, agentId, setAgentId, agendaId, setAgendaId, setSelectedAppointment, showUpdateModal, setShowUpdateModal, setShowDetailModal, selectedRowData, setSelectedRowData, setAppointmentDetails, selectedDate, setSelectedDate, loading, setLoading, agentCommercialUsers, agendas, setAgendas,
		setContactName, setContactEmail, contactAgendas, setContactAgendas, deletedAppointmentIds, setUserAppointments,
	} = useCalendar();
	const userContext = useAuth();
	const [selectedAppointmentDate, setSelectedAppointmentDate] =
		useState(null);
	const [showBlockModal, setShowBlockModal] = useState(false);
	const [showUpdateSupBlock, setUpdateSupBlock] = useState(false);
	const [showBlockModalChoice, setShowBlockModalChoice] = useState(false);
	const [supBlock, setSupBlock] = useState(false);
	const [modalShown, setModalShown] = useState(false);
	const [showAlertBlockSup, setShowAlertBlockSup] = useState(false);
	const [showAlertAddRdv, setShowAlertAddRdv] = useState(false);
	const [askSupModal, setAskSupModal] = useState(false);
	const [lastActivityTime, setLastActivityTime] = useState(Date.now());
	const [warningModalVisible, setWarningModalVisible] = useState(false);
	const [refreshNeeded, setRefreshNeeded] = useState(false);
	const history = useHistory();
	const [calendarKey, setCalendarKey] = useState(Date.now());
	useEffect(() => {
		if (userContext.userRole === 'Agent') {
			const interval = setInterval(checkInactivity, 10000);

			return () => clearInterval(interval);
		}
	}, [lastActivityTime, warningModalVisible]);

	useEffect(() => {
		localStorage.setItem("deletedAppointmentIds", JSON.stringify(deletedAppointmentIds));
	}, [deletedAppointmentIds]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setLoading(false);
		}, 2000);
		return () => clearTimeout(timeout);
	}, []);

	useEffect(() => {
		const pusher = new Pusher(pusherKey, {
			cluster: 'eu',
			encrypted: true,
			logToConsole: true,
		});

		console.log('Pusher initialized.', pusher);

		const channel = pusher.subscribe('rdvs');

		const handleRdvUpdated = (data) => {
			if (data && data.rdv) {
				console.log('Event triggered for rdv.updated.', data.rdv);

				setAppointments((prevAppointments) => {
					const appointmentMap = new Map(prevAppointments.map(appt => [appt.id, appt]));

					appointmentMap.set(data.rdv.id, data.rdv);
					const updatedAppointments = Array.from(appointmentMap.values());

					console.log('Updated appointments:', updatedAppointments);
					setCalendarKey(Date.now());
					setRefreshNeeded(true);
					return updatedAppointments;
				});

			} else {
				setRefreshNeeded(false);
				console.error('Invalid data received from Pusher:', data);
			}
		};

		const handleRdvDeleted = (data) => {
			if (data && Array.isArray(data.deletedIds)) {
				console.log('Event triggered for rdv.deleted.', data.deletedIds);

				setAppointments((prevAppointments) => {
					const updatedAppointments = prevAppointments.filter(appt => !data.deletedIds.includes(appt.id));
					console.log('Updated appointments after deletion:', updatedAppointments);
					setCalendarKey(Date.now());
					setRefreshNeeded(true);
					return updatedAppointments;
				});
				// window.location.reload();
			} else {
				console.error('Invalid data received from Pusher for deletion:', data);
			}
		};

		channel.bind('rdv.updated', handleRdvUpdated);
		channel.bind('rdv.deleted', handleRdvDeleted);

		return () => {
			channel.unbind('rdv.updated', handleRdvUpdated);
			channel.unbind('rdv.deleted', handleRdvDeleted);
			pusher.unsubscribe('rdvs');
			pusher.disconnect();
		};
	}, [setAppointments]);




	// 	useEffect(() => {
	// 		// Fetch agendas and appointments on component mount
	// 		fetchAgendasAndAppointments(setAgendas, setAppointments);

	// 		// Initialize Pusher
	// 		const pusher = new Pusher('b488dbc1f2b173733194', {
	// 				cluster: 'eu',
	// 				encrypted: true,
	// 				logToConsole: true 
	// 		});

	// 		console.log('Pusher initialized.');

	// 		// Subscribe to the channel and bind to the event
	// 		const channel = pusher.subscribe('rdvs');
	// 		channel.bind('rdv.updated', (data) => {
	// 			console.log('Event triggered for rdv.updated.');

	// 			// Log the raw event data received
	// 			console.log('Received raw event data:', data);

	// 			// Check if the event data contains the expected structure
	// 			if (data && data.rdvs) {
	// 					console.log('Event data contains rdvs:', data.rdvs);

	// 					// Update the state by merging the new rdvs with the existing appointments
	// 					setAppointments((prevAppointments) => {
	// 							// Create a new array by merging previous appointments with new ones
	// 							const updatedAppointments = [...prevAppointments];

	// 							data.rdvs.forEach((newAppointment) => {
	// 									// Check if the new appointment already exists in the state
	// 									const index = updatedAppointments.findIndex(
	// 											(appointment) => appointment.id === newAppointment.id
	// 									);

	// 									if (index !== -1) {
	// 											// If it exists, replace it
	// 											updatedAppointments[index] = newAppointment;
	// 									} else {
	// 											// If it doesn't exist, add it to the array
	// 											updatedAppointments.push(newAppointment);
	// 									}
	// 							});

	// 							return updatedAppointments;
	// 					});
	// 			} else {
	// 					console.error('Event data does not contain expected rdvs array. Data:', data);
	// 			}
	// 	});


	// 		// Cleanup on component unmount
	// 		return () => {
	// 				pusher.unsubscribe('rdvs');
	// 				pusher.disconnect();
	// 		};
	// }, [setAgendas, setAppointments]);

	// 	useEffect(() => {
	//     // Fetch agendas and appointments on component mount
	//     fetchAgendasAndAppointments(setAgendas, setAppointments);

	//     // Initialize Pusher
	//     const pusher = new Pusher('b488dbc1f2b173733194', {
	//         cluster: 'eu',
	//         encrypted: true,
	//         logToConsole: true 
	//     });

	//     console.log('Pusher initialized.');

	//     // Subscribe to the channel and bind to the event
	//     const channel = pusher.subscribe('rdvs');
	//     channel.bind('rdv.updated', (data) => {
	//         console.log('Event triggered for rdv.updated.');

	//         // Log the raw event data received
	//         console.log('Received raw event data:', data);

	//         // Check if the event data contains the expected structure
	//         if (data && data.rdv) {
	//             console.log('Event data contains rdv:', data.rdv);

	//             // Update the state by merging the new rdv with the existing appointments
	//             setAppointments((prevAppointments) => {
	//                 // Create a new array by merging previous appointments with new one
	//                 const updatedAppointments = [...prevAppointments];

	//                 // Check if the new rdv already exists in the state
	//                 const index = updatedAppointments.findIndex(
	//                     (appointment) => appointment.id === data.rdv.id
	//                 );

	//                 if (index !== -1) {
	//                     // If it exists, replace it
	//                     updatedAppointments[index] = data.rdv;
	//                 } else {
	//                     // If it doesn't exist, add it to the array
	//                     updatedAppointments.push(data.rdv);
	//                 }

	//                 return updatedAppointments;
	//             });
	//         } else {
	//             console.error('Event data does not contain expected rdv object. Data:', data);
	//         }
	//     });

	//     // Cleanup on component unmount
	//     return () => {
	//         pusher.unsubscribe('rdvs');
	//         pusher.disconnect();
	//     };
	// }, [setAgendas, setAppointments]);

	useEffect(() => {
		fetchAgendasAndAppointments(setAgendas, setAppointments);
	}, [setAgendas, setAppointments]);
	useEffect(() => {
		if (refreshNeeded) {
			fetchAgendasAndAppointments(setAgendas, setAppointments)
				.finally(() => setRefreshNeeded(false)); 
		}
	}, [refreshNeeded, setAgendas, setAppointments]);


	const handleUserActivity = () => {
		setLastActivityTime(Date.now());
		if (warningModalVisible) {
			setWarningModalVisible(false);
		}
	};

	const checkInactivity = () => {
		const currentTime = Date.now();
		const inactiveTime = currentTime - lastActivityTime;

		if (inactiveTime > 30 * 60 * 1000) {
			setWarningModalVisible(true);
		}
	};

	const handleCloseModal = () => {
		setShowAddModal(false);
		setShowAlertAddRdv(false)
	};

	const handleCloseBlockModal = () => {
		setShowBlockModal(false);
		setSupBlock(false)
		setUpdateSupBlock(false)
		setShowAlertBlockSup(false)
	};

	const handleRollback = () => {
		setShowUpdateModal(false);
	};

	const handleAppointmentClickCallback = (event, agendaId) => {
		const { extendedProps } = event;
		const { reserver_plage_horaire, agent, bloquer, rdv_prive } = extendedProps;
		const isReservedAppointment = checkIfReservedAppointment(reserver_plage_horaire, agent);
		const isBlockedAppointment = checkIfBlockedAppointment(bloquer);
		const isOwnerOfBlockedAppointment = checkIfOwnerOfBlockedAppointment(isBlockedAppointment, agent);
		const isNormalAppointment = checkIfNormalAppointment(bloquer, reserver_plage_horaire, rdv_prive);
		const hasAccess = checkUserAccess(userContext.userRole, agent);

		if (isReservedAppointment) {
			showModal(event);
		} else if (!hasAccess && agent != userContext.userId && !isBlockedAppointment && !isNormalAppointment) {
			console.log('reserve restreint ')
			console.log('isBlockedAppointment ', isBlockedAppointment, typeof isBlockedAppointment)
			console.log('isNormalAppointment ', isNormalAppointment, typeof isNormalAppointment)
			setModalShown(true);
			showWarning('Accès restreint', "Vous n'êtes pas autorisé à modifier ce rendez-vous résérvé.");
			return;
		}

		if (isBlockedAppointment && !isOwnerOfBlockedAppointment) {
			console.log('isOwnerOfBlockedAppointment ', isOwnerOfBlockedAppointment, typeof isOwnerOfBlockedAppointment)
			console.log('bloqué restreint ')
			showWarning('Accès restreint', "Vous n'êtes pas autorisé à modifier ce rendez-vous bloqué.");
			setShowUpdateModal(false);
			return;
		}

		if (isNormalAppointment) {
			history.push(`/rdv-detail/${event.id}`);
		} else {
			setShowUpdateModal(true);
		}
		setAppointmentDetailsAndContactInfo(event, agendaId);
	};

	const checkIfReservedAppointment = (reserver_plage_horaire, agent) => {
		console.log('checkIfReservedAppointment called')
		console.log('reserver_plage_horaire ', reserver_plage_horaire)
		console.log('agent id', agent)
		console.log('context user id ', userContext.userId)
		return reserver_plage_horaire == 1 && (agent == userContext.userId || userContext.userRole == 'Superviseur' || userContext.userRole == 'Admin'  || userContext.userRole == 'superadmin');
	};

	const checkIfBlockedAppointment = (bloquer) => {
		console.log('checkIfBlockedAppointment called')
		console.log('bloquer', bloquer)
		return bloquer == 1;
	};

	const checkIfOwnerOfBlockedAppointment = (isBlockedAppointment, agent) => {
		return isBlockedAppointment && agent == userContext.userId;
	};

	const checkUserAccess = (userRole, agent) => {
		return userRole == 'Superviseur' || userRole == 'Admin' || userRole == 'superadmin';
	};
	const checkIfNormalAppointment = (bloquer, reserver_plage_horaire, rdv_prive) => {
		console.log('checkIfNormalAppointment called')
		console.log('bloquer ', bloquer)
		console.log('reserver_plage_horaire ', reserver_plage_horaire)
		console.log('rdv_prive ', rdv_prive)
		return bloquer == 0 && reserver_plage_horaire == 0 && rdv_prive == 0;
	};

	const showWarning = (title, content) => {
		Modal.warning({
			title,
			content,
		});
	};

	const setAppointmentDetailsAndContactInfo = (event, agendaId) => {

		const agenda = contactAgendas.find(item => item.id == agendaId);
		const contact = agentCommercialUsers.find(user => user.id == agenda?.contact_id);
		const contactName = contact ? `${contact.prenom} ${contact.nom}` : 'Unknown';
		const contactEmail = contact ? contact.email : 'Unknown';

		handleAppointmentClick(
			event,
			userContext,
			setAgentId,
			setAppointmentDetails,
			setSelectedRowData,
			setShowDetailModal,
			setSelectedAppointment,
			axiosClient,
			agendaId
		);

		setSelectedAppointmentDate(event.start);
		setContactName(contactName);
		setContactEmail(contactEmail);
	};

	const handleDeleteAppointment2 = async (id) => {
		try {
			await axiosClient.delete(`rdvs/${id}`);
		} catch (error) {
			console.error('Failed to delete Rdv:', error.response?.data?.message || error.message);
		}
	};

	const showModal = (event) => {
		const handleModifier = () => {
			setAskSupModal(false);
			setUpdateSupBlock(true);
			Modal.destroyAll();
		};

		const handleSupprimer = () => {
			Modal.confirm({
				title: "Confirmation de suppression",
				content: "Êtes-vous sûr de vouloir supprimer ce rendez-vous?",
				okText: "Oui",
				cancelText: "Non",
				onOk: () => {
					handleDeleteAppointment2(event.id);
					setAskSupModal(false);
					Modal.destroyAll();
				},
				onCancel: () => {
					setAskSupModal(false);
				}
			});
		};

		Modal.confirm({
			title: "Sélectionner une action",
			content: (
				<div>
					<Row gutter={[16, 16]}>
						<Col>
							<ModifierButton onClick={handleModifier} buttonText="Modifier" />
						</Col>
						<Col>
							<SupprimerButton onClick={handleSupprimer} buttonText="Supprimer" />
						</Col>
					</Row>
				</div>
			),
			onCancel: () => setAskSupModal(false),
			cancelText: "Annuler",
			okButtonProps: { style: { display: 'none' } },
			cancelButtonProps: { style: { display: 'block', float: 'right' } },
			footer: null,
			width: '40%',
		});
	};

	const dayCellContent = (arg) => {
		return (
			<div style={{ backgroundColor: "", height: "100%", width: "100%" }}>
				{arg.dayNumberText}
			</div>
		);
	};

	const handleDeleteAppointment = async (appointmentId) => {
		try {
			const id = parseInt(appointmentId);
			const appointmentExists = filteredAppointments.find(appointment => appointment.id == id);
			if (!appointmentExists) {
				return;
			}

			await axiosClient.delete(`rdvs/${id}`);
			const updatedAppointments = filteredAppointments.filter(
				(appointment) => appointment.id != id
			);
			setAppointments(updatedAppointments);

		} catch (error) {
			const id = parseInt(appointmentId);
			console.error("Error deleting appointment:", error.response);
			const updatedAppointments = filteredAppointments.filter(
				(appointment) => appointment.id != id
			);
			setAppointments(updatedAppointments);
		}
	};

	const handleAddAppointmentCallback = (arg, agendaId,) => {
		const selectedDate = new Date(arg.date);
		selectedDate.setHours(0, 0, 0, 0);
		const currentDate = new Date();
		currentDate.setHours(0, 0, 0, 0);

		if (userContext.userRole == "Agent" && userContext.userRole != "Superviseur" && userContext.userRole != "Admin" && userContext.userRole != "superadmin") {

			if (selectedDate.getTime() == currentDate.getTime()) {
				Modal.warning({
					title: "Action non autorisée",
					content: "Vous ne pouvez pas ajouter ou bloquer des rendez-vous aujourd'hui.",
				});
				return;
			}
			const options = { weekday: 'long' };
			const dayName = currentDate.toLocaleDateString('en-US', options);
			let maxDaysAllowed
			if (dayName == "Monday" || dayName == "Tuesday") {
				maxDaysAllowed = 3;
			} else if (dayName == "Thursday" || dayName == "Wednesday" || dayName == "Friday") {
				maxDaysAllowed = 5;
			}

			currentDate.setHours(0, 0, 0, 0);

			const timeDifference = Math.abs(selectedDate.getTime() - currentDate.getTime());
			const differenceInDays = Math.floor(timeDifference / (1000 * 3600 * 24));

			if (differenceInDays > maxDaysAllowed) {

				Modal.warning({
					title: "Warning",
					content: `Vous ne pouvez sélectionner qu'une date dans les prochains 3 jours.`,
				});
				return;
			}
		}

		if (selectedDate < currentDate) {
			Modal.warning({
				title: "Impossible d'ajouter un rendez-vous",
				content: "Vous ne pouvez pas ajouter de rendez-vous à des dates passées.",
			});
			return;
		}

		const endTime = new Date(selectedDate.getTime() + 60 * 60000);
		const appointmentsForDate = appointments.filter(appointment =>
			appointment.agendaId == agendaId &&
			new Date(appointment.start_date) >= selectedDate &&
			new Date(appointment.end_date) <= endTime
		);

		if (appointmentsForDate.length > 0) {
			Modal.warning({
				title: "Créneau non disponible",
				content: "Le créneau sélectionné n'a pas au moins 1 heure de disponibilité.",
			});
			return;
		}

		const onCancel = () => {
			setShowBlockModalChoice(false);
		};

		const onAddAppointment = () => {
			setShowAddModal(true);
			setSelectedDate({ date: arg.date, agentId, agendaId });
			setShowAddModal(true);
			Modal.destroyAll();
		};

		const onBlockCreanaux = () => {
			setShowBlockModal(true);
			setSelectedDate({ date: arg.date, agentId, agendaId });
			setShowBlockModal(true);
			Modal.destroyAll();
		};

		const agenda = contactAgendas.find(item => item.id == agendaId);
		const contact = agentCommercialUsers.find(user => user.id == agenda?.contact_id);
		const contactName = contact ? `${contact.prenom} ${contact.nom}` : 'Unknown';
		const contactEmail = contact ? contact.email : 'Unknown';
		console.log('contact', contact)
		console.log('contactName', contactName)
		console.log('contactEmail', contactEmail)

		Modal.confirm({
			title: "Sélectionner une action",
			content: (
				<Row gutter={[16, 16]}>
					<Col>
						<NewButton
							onClick={onAddAppointment}
							buttonText="Ajouter Rdv"
						/>
					</Col>
					<Col>
						<Button
							icon={<StopOutlined />}
							onClick={onBlockCreanaux}
							style={{ border: "1px solid #FC6736", color: "#FC6736" }}
							className="block-btn"
						>
							Bloque Crénaux
						</Button>
					</Col>
				</Row>
			),
			onCancel: onCancel,
			okButtonProps: { style: { display: 'none' } },
			cancelButtonProps: { style: { display: 'block', float: 'right' } },
			cancelText: "Annuler",
			footer: null,
			width: '40%',
		});
		setContactName(contactName);
		setContactEmail(contactEmail);
	};

	const handleDateSelect = (arg, agendaId) => {
		const start = arg.start;
		const end = arg.end;
		const timeDifference = end - start;
		if (timeDifference > 30 * 60 * 1000) {
			setSupBlock(true);
			setSelectedDate({ start, end, agendaId });
		}
	};

	const handleFormSubmitCallback = async (newAppointment) => {
		try {
			setAppointments(prevAppointments => [...prevAppointments, newAppointment]);
			setUserAppointments(prevAppointments => [...prevAppointments, newAppointment]);
			handleCloseModal();
			handleCloseBlockModal()
			setShowUpdateModal(false);
			setSelectedDate(null);
			await fetchAgendasAndAppointments(
				setAgendas, setAppointments
			);
		} catch (error) {
			console.error("Error handling form submit:", error);
		}
	};

	const filteredAppointments = appointments.filter(
		(appointment) => !deletedAppointmentIds.includes(appointment.id)
	);


	const getEvents = (agenda) => {
		return appointments
			.filter((appointment) => appointment.agendaId == agenda.id)
			.map((appointment) => {
				const showPrenom = appointment.prenom && appointment.prenom.toLowerCase() !== "null";
				const status = appointment.status == "confirmer" ? "Conf" : appointment.status;
				let title = '';

				if (appointment.bloquer == 1) {
					title = `${appointment.postal}`;
				} else {
					title = `${appointment.postal} / ${appointment.nom}${showPrenom ? ' ' + appointment.prenom : ''}`;
				}
				const client = appointment.prenom
					? `${appointment.nom} ${appointment.prenom}`
					: `${appointment.nom}`;

				return {
					id: appointment.id,
					title: title,
					start: appointment.start_date,
					end: appointment.end_date,
					status: status,
					bloquer: appointment.bloquer,
					titre: appointment.titre,
					note: appointment.note,
					postal: appointment.postal,
					reserver_plage_horaire: appointment.reserver_plage_horaire,
					agent: appointment.id_agent,
					agenda: appointment.id_agenda,
					status_ac: appointment.status_ac,
					tel: appointment.tel,
					user: `${appointment.user.nom} ${appointment.user.prenom}`,
					client: client,
					rdv_prive: appointment.rdv_prive
				};
			});
	};

	console.log('calendarKey', calendarKey)
	console.log('filteredAppointments', filteredAppointments.length)
	console.log('app', appointments.length)
	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					marginBottom: "30px",
				}}
			>
				{loading ? (
					<div style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: '100vh',
						width: '100vw',
					}}>
						<Spin size="large" />
					</div>

				) : (
					<>
						<ContactList
							selectedItems={selectedItems}
							setSelectedItems={setSelectedItems}
							agendas={agendas}
							agentId={agentId}
							setAgentId={setAgentId}
							agendaId={agendaId}
							setAgendaId={setAgendaId}
							role={userContext.userRole}
							contactAgendas={contactAgendas}
							setContactAgendas={setContactAgendas}
						/>
						{contactAgendas.length == 0 ? (
							<Card style={{ width: "80%" }}><div style={{ textAlign: "center", }}>
								<Empty description="Veuillez sélectionner un agenda pour commencer" />
							</div></Card>
						) : (
							<Card style={{ width: "80%" }}>
								{selectedItems.length > 0 && (
									<>
										{contactAgendas.map((agenda) => {

											const user = agentCommercialUsers.find((user) => {
												if (user.id == agenda.contact_id) {
													return true;
												}
												return false;
											});

											const capitalizeFirstLetter = (string) => {
												return string.charAt(0).toUpperCase() + string.slice(1);
											};
											const userName = user
												? `${capitalizeFirstLetter(user.prenom)} ${capitalizeFirstLetter(user.nom)}`
												: "Unknown";

											return (
												<div key={agenda.id}>
													<h2>{userName}</h2>{" "}
													{agenda.fullcalendar_config && (
														<FullCalendar
															key={calendarKey}
															plugins={[
																dayGridPlugin,
																timeGridPlugin,
																interactionPlugin,
															]}
															{...JSON.parse(
																agenda.fullcalendar_config
															)}
															nowIndicator={true}

															eventContent={(arg) => {
																const { extendedProps, id, title } = arg.event;

																const { bloquer, reserver_plage_horaire, rdv_prive, postal, status, status_ac, user, titre, note, client } = extendedProps;

																const getStatusColor = (status) => {
																	switch (status) {
																		case 'Conf':
																			return '#40A578';
																		case 'NRP':
																			return '#FF8A08';
																		default:
																			return 'black';
																	}
																};

																if (bloquer == 1) {
																	return (
																		<div className="fc-event-title">
																			<CountdownTimer appointmentId={id} onDelete={() => handleDeleteAppointment(id)} />
																			<div>{title} / {user}</div>
																		</div>
																	);
																} else if (reserver_plage_horaire == 1) {

																	return (
																		<div className="fc-event-title">
																			<div>
																				{postal ? `${postal} - ${titre || note}` : `${titre || note}`}
																			</div>
																		</div>
																	);
																} else if (rdv_prive == 1) {

																	return (
																		<div className="fc-event-title">
																			<div>{postal} - RDV privé</div>
																		</div>
																	);
																} else {
																	const statusColor = getStatusColor(status);
																	return (
																		<div className="fc-event-title">
																			{status_ac ? <span>{status_ac} / </span> : null}
																			<span style={{ color: statusColor }}>{status}</span>
																			{postal ? ` / ${postal}` : null}
																			{client ? ` / ${client}` : null}
																		</div>
																	);
																}

															}}


															dayCellContent={dayCellContent}
															eventDidMount={(arg) => {
																arg.el.style.backgroundColor = "#cfdddbdf";

															}}
															dateClick={(arg) => {
																handleAddAppointmentCallback(
																	arg,
																	agenda.id
																)
																handleUserActivity();
															}
															}
															select={(arg) => {
																handleDateSelect(
																	arg,
																	agenda.id
																)
															}}
															eventClick={(info) => {
																handleAppointmentClickCallback(info.event, agenda.id);
																handleUserActivity();
															}}
															events={getEvents(agenda)}
															views={{
																week: {
																	type: "timeGridWeek",
																	duration: {
																		weeks: 1,
																	},
																},
															}}
															initialView="week"
															slotMinTime="09:00"
															slotMaxTime="19:00"
															weekends={false}
															height="auto"
															selectable={agenda.isDisabled == 0 && (userContext.userRole === "Superviseur" || userContext.userRole === "Admin" || userContext.userRole === "superadmin")}
															eventAdd={() => handleUserActivity()}

														/>
													)}
												</div>
											);
										})}
									</>
								)
								}
							</Card>
						)}
						<Modal
							maskClosable={false}
							keyboard={false}
							open={showAddModal}
							title={`Nouveau rendez-vous`
							}
							onCancel={handleCloseModal}
							footer={null}
							width={1000}
							height={"auto"}
						>
							<AddAppointment
								selectedDate={selectedDate}
								onFormSubmit={handleFormSubmitCallback}
								userRole={userContext.userRole}
								agentId={agentId}
								agendaId={agendaId}
								selectedAppointmentDate={selectedAppointmentDate}
								setShowAlertAddRdv={setShowAlertAddRdv}
								showAlertAddRdv={showAlertAddRdv}
								showAddModal={showAddModal}
								setShowAddModal={setShowAddModal}
							/>
						</Modal>

						{selectedRowData && selectedRowData.rdv.bloquer == 1 ? (
							<Modal
								maskClosable={false}
								keyboard={false}
								open={showUpdateModal}
								onCancel={() => setShowUpdateModal(false)}
								footer={null}
								style={{ marginTop: "-50px" }}
								width="85%"
								bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
								destroyOnClose
								title={
									<Row justify="space-between" align="middle">
										<Col span={14}>
											{selectedRowData?.rdv.bloquer == 1 && (
												<p style={{ fontSize: "16px" }}>
													Modifier rendez-vous
													{/* : {contactName} - {contactEmail} */}
												</p>
											)}
										</Col>
									</Row>
								}
							>
								{selectedRowData && selectedRowData.rdv.bloquer == 1 && (
									<>
										<UpdateRdv
											initialValues={selectedRowData.rdv}
											agendaId={agendaId}
											agentId={agentId}
											onFormSubmit={handleFormSubmitCallback}
											handleRollback={handleRollback}
											setShowUpdateModal={setShowUpdateModal}
										/>
									</>
								)}
							</Modal>
						) : null}
						<Modal
							maskClosable={false}
							keyboard={false}
							open={showBlockModal}
							title="Bloquer Créneaux"
							onCancel={handleCloseBlockModal}
							footer={null}
							width="50%"
						>
							<BlockRdv

								selectedDate={selectedDate}
								onFormSubmit={handleFormSubmitCallback}
								selectedAppointmentDate={selectedAppointmentDate}
								agentId={agentId}
								agendaId={agendaId}
								setShowBlockModal={setShowBlockModal}
							/>
						</Modal>
						<Modal
							maskClosable={false}
							keyboard={false}
							open={supBlock}
							title="Reserver Créneaux"
							onCancel={handleCloseBlockModal}
							footer={null}
							width="50%"
						>
							<SupBlock
								selectedDate={selectedDate}
								onFormSubmit={handleFormSubmitCallback}
								selectedAppointmentDate={selectedAppointmentDate}
								agentId={agentId}
								agendaId={agendaId}
								setShowAlert={setShowAlertBlockSup}
								showAlert={showAlertBlockSup}
								setSupBlock={setSupBlock}
							/>
						</Modal>
						<Modal
							maskClosable={false}
							keyboard={false}
							open={showUpdateSupBlock}
							title="Reserver Créneaux"
							onCancel={handleCloseBlockModal}
							footer={null}
							width="50%"
						>
							<UpdateSupBlock
								initialValues={selectedRowData?.rdv}
								agendaId={agendaId}
								agentId={agentId}
								onFormSubmit={handleFormSubmitCallback}
							/>
						</Modal>
						<Modal
							maskClosable={false}
							keyboard={false}
							title="Alerte d'inactivité"
							open={warningModalVisible}
							onCancel={() => setWarningModalVisible(false)}
							footer={[
								<Button key="ok" onClick={() => setWarningModalVisible(false)}>
									OK
								</Button>,
							]}
							bodyStyle={{ padding: '12px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', height: '400px' }}
							style={{ top: '5%' }}
						>
							<div>
								<p style={{ marginBottom: '16px', color: '#F5004F', fontWeight: 'bold', fontSize: '19px' }}>Vous avez été inactif pendant plus de 10 minutes. Veuillez reprendre l'activité.</p>
								<img src={angry} alt='angry' width={300} />
							</div>
						</Modal>
					</>
				)
				}
			</div>
		</>

	);
}

export default Calendar;
