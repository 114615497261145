import React, { useState, useEffect } from "react";
import { Space, Table, Button, Row, Col, Card, Tag, Modal } from "antd";
import UpdateUser from "../updateUser/UpdateUser";
import { fetchUsersData, handleDeleteUser } from "../services/api";
import { pencil, deletebtn } from "../../../constants/icons";
import { useHistory } from "react-router-dom";
import SearchInput from "../../../constants/SearchInput";
import NewButton from "../../../constants/NewButton";
import { EyeOutlined } from "@ant-design/icons";
import UserDetail from "../Store/userDetail";
import UserCount from "../userStatistiques/UserCount";
import { useAuth } from "../../../AuthContext";
import { highlightText, filterUsers, modifyUsers } from "../Utils/utils";
import ImageLoader from './ImageLoader';
import { baseURL2 } from "../../../api/axios";

const DisplayUsers = () => {
    const [users, setUsers] = useState([]);
    const [updateModalVisible, setUpdateModalVisible] = useState(false);
    const [updateData, setUpdateData] = useState({});
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [detailsModalVisible, setDetailsModalVisible] = useState(false);
    const { userRole } = useAuth();
    const history = useHistory();

    const handleButtonClick = () => {
        history.push("/creer-utilisateur");
    };

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
			console.log('calling fetchUsersData from user table')

        fetchUsersData(userRole).then(userData => {
            if (isMounted) {
                setUsers(userData);
                setLoading(false);
            }
        });

        return () => {
            isMounted = false;
        };
    }, [userRole]);

    const showUpdateModal = (record) => {
			console.log('showUpdateModal called')
        if ((userRole === "Superviseur") && record.role === "Admin") {
            Modal.warning({
                title: "Opération non autorisée",
                content: "Vous n'êtes pas autorisé à mettre à jour ce profil. Seuls les administrateurs peuvent le faire.",
            });
            return;
        }
        setUpdateData(record);
        setUpdateModalVisible(true);
    };

    const roleColors = {
        Admin: "success",
        Superviseur: "error",
        Confirmateur: "lime",
        Agent: "orange",
        "Agent Commercial": "purple",
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "10%",
            render: (text) => <span>{text}</span>,
        },
        {
            title: "NOM",
            dataIndex: "name",
            key: "name",
            width: "32%",
            render: (text, record) => {
                const imageUrl = `${baseURL2}/storage/${record.image}`;
                return (
                    <div key={record.id}>
                        <ImageLoader
                            src={imageUrl}
                            alt={`${record.nom} ${record.prenom}`}
                        />
                        <span style={{ marginLeft: 8 }}>
                            {highlightText(`${record.nom} ${record.prenom}`, searchText)}
                        </span>
                    </div>
                );
            },
        },
        {
            title: "EMAIL",
            dataIndex: "email",
            key: "email",
            render: (text) => highlightText(text, searchText),
        },
        {
            title: "Statut",
            dataIndex: "isActive",
            key: "isActive",
            render: (isActive) => (
                <Tag color={isActive == 1 ? 'green' : 'red'}>
                    {isActive == 1 ? 'Actif' : 'Inactif'}
                </Tag>
            ),
        },
        {
            title: "ROLE",
            dataIndex: "role",
            key: "role",
            render: (text) => (
                <Tag color={roleColors[text]}>{highlightText(text, searchText)}</Tag>
            ),
        },
        {
            title: "ACTION",
            key: "action",
            render: (text, record) => (
                <Space>
                    <Button type="link" onClick={() => handleDetailsClick(record)}>
                        <EyeOutlined />
                    </Button>
                    <Button type="link" onClick={() => showUpdateModal(record)}>
                        {pencil}
                    </Button>
                    <Button type="link" onClick={() => handleDeleteUser(record.id, userRole, setUsers)}>
                        {deletebtn}
                    </Button>
                </Space>
            ),
        },
    ];

    const handleDetailsClick = (record) => {
        setSelectedRowData(record);
        setDetailsModalVisible(true);
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const modifiedUsers = modifyUsers(users);
    const filteredUsers = filterUsers(modifiedUsers, searchText);

    return (
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <UserCount />
            <Card>
                <Row style={{ margin: "10px 0px" }}>
                    <Col span={12} style={{ textAlign: "left", fontWeight: "bold", fontSize: "20px" }}>
                        Liste des Utilisateurs
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <NewButton onClick={handleButtonClick} loading={loading} buttonText="Nouveau utilisateur" />
                    </Col>
                </Row>
                <SearchInput onChange={handleSearch} />
                <Table
                    columns={columns}
                    loading={loading}
                    dataSource={filteredUsers}
                    pagination={{ pageSize: 5 }}
                    responsive={{
                        xs: 1,
                        sm: 3,
                    }}
                    style={{ padding: "10px 1px" }}
                    className="table-container"
                />
            </Card>
            <UpdateUser
                visible={updateModalVisible}
                onCancel={() => setUpdateModalVisible(false)}
                onUpdate={() => fetchUsersData(userRole).then(userData => setUsers(userData))}
                userData={updateData}
            />
            <Modal
                title="Consulter utilisateur"
                visible={detailsModalVisible}
                onCancel={() => setDetailsModalVisible(false)}
                footer={null}
                width="37%"
                destroyOnClose
            >
                {selectedRowData && <UserDetail selectedRowData={selectedRowData} />}
            </Modal>
        </div>
    );
};

export default DisplayUsers;
