import React, { useState, useEffect } from "react";
import { Table, Space, Button, Row, Col, Modal, Avatar } from "antd";
import { useHistory } from "react-router-dom";
import { pencil } from "../../../constants/icons";
import useColumnSearch from "../../../constants/tableSearchLogin";
import { EyeOutlined } from "@ant-design/icons";
import NewButton from "../../../constants/NewButton";
import UserDetail from "../../user/Store/userDetail";
import UpdateUserModal from "../../user/updateUser/UpdateUser";
import { fetchContactsUsers } from "../services/api";
import { useAuth } from "../../../AuthContext";
import profilePic from '../../../assets/images/profile-user.png'
import { baseURL2 } from "../../../api/axios";
import { useCalendar } from "../../../CalendarContext";

const Contacts = () => {
    const [visible, setVisible] = useState(false);
    const [agentCommercialUsers, setAgentCommercialUsers] = useState([]);
    const [updateData, setUpdateData] = useState({});
    const [loading, setLoading] = useState(false);
    const [detailsModalVisible, setDetailsModalVisible] = useState(false);

    const history = useHistory();
    const { getColumnSearchProps } = useColumnSearch();
    const { setContacts } = useCalendar();

    useEffect(() => {
        fetchContactsUsers(setAgentCommercialUsers, setContacts);
    }, []);

    const handleDetailsClick = (record) => {
        setUpdateData(record);
        setDetailsModalVisible(true);
    };

    const showUpdateModal = (record) => {
        setUpdateData(record);
        setVisible(true);
    };

    const handleButtonClick = () => {
        history.push("/creer-utilisateur");
    };

    const columns = [
			{
				title: "ID",
				dataIndex: "id",
				key: "id",
				width: "10%",
				render: (text) => <span>{text}</span>,
		},
        {
            title: "Agent Commercial",
            dataIndex: "name",
            key: "name",
            width: "32%",
            ...getColumnSearchProps("name"),
            render: (_, record) => (
                <div>
                    <Avatar 
										// src={profilePic} 
										src={`${baseURL2}/storage/${record.image}`} 
										/>
                    <span style={{ marginLeft: 8 }}>{record.prenom} {record.nom}</span>
                </div>
            ),
        },
        {
            title: "EMAIL",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps("email"),
        },
        {
            title: "ACTION",
            key: "action",
            dataIndex: "action",
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="link"
                        onClick={() => handleDetailsClick(record)}
                    >
                        <EyeOutlined />
                    </Button>
                    <Button
                        type="link"
                        className="darkbtn"
                        onClick={() => showUpdateModal(record)}
                    >
                        {pencil}
                    </Button>
                </Space>
            ),
        },
    ];

    const dataSource = agentCommercialUsers.map((user) => ({
        ...user,
        name: `${user.prenom} ${user.nom}`,
    }));

    return (
        <>
            <div
                style={{
                    backgroundColor: "white",
                    padding: "5px",
                    boxShadow: "0px 20px 27px #0000000d",
                }}
            >
                <Row style={{ margin: "10px 20px" }}>
                    <Col
                        span={12}
                        style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "20px",
                        }}
                    >
                        Liste des contacts
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <NewButton
                            onClick={handleButtonClick}
                            loading={loading}
                            buttonText="Nouveau utilisateur"
                        />
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    loading={loading}
                    dataSource={dataSource}
                    pagination={{ pageSize: 5 }}
                    responsive={{ xs: 1, sm: 1 }}
                    className="table-container"
                />
                <UpdateUserModal
                    visible={visible}
                    onCancel={() => setVisible(false)}
                    onUpdate={() => fetchContactsUsers(setAgentCommercialUsers, setContacts)}
                    userData={updateData}
                />
                <Modal
                    title="Consulter contact"
                    visible={detailsModalVisible}
                    onCancel={() => setDetailsModalVisible(false)}
                    footer={null}
                    width="37%"
                    destroyOnClose
                >
                    {updateData && (
                        <UserDetail
                            selectedRowData={updateData}
                        />
                    )}
                </Modal>
            </div>
        </>
    );
};

export default Contacts;
