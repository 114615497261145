// import React from "react";
// import "./HomeCompanies.css";
// import { Link } from "react-router-dom";
// import { Row, Col, Button } from "antd";
// import logo from "../../assets/images/logo orange.png";
// import gy from "../../assets/images/brandpart.jpg";
// import expertLogo from "../../assets/images/expectBack.jpg";
// import expertBack from "../../assets/images/EXI.jpg";
// import servihome from "../../assets/images/back-engie.jpg";
// import sunlogo from "../../assets/images/back-luminus.jpg";
// import sunsymbole from "../../assets/images/sunlight.jpg";
// import servisymbol from "../../assets/images/sh.jpg";
// import optinergy from "../../assets/images/back-engie.jpg";
// import optisymbole from "../../assets/images/optyener.jpg";
// import gysymbol from "../../assets/images/gy.jpg";

// const CompanyCard = ({ logoFrontSrc, logoBackSrc, buttonText, buttonLink }) => {
// 	return (
// 		<Col span={{ lg: 5, md: 12, sm: 24 }}>

// 			<Link to={buttonLink}>
// 				<div className="card2">
// 					<div
// 						className="content2 front"
// 						style={{ backgroundImage: `url(${logoFrontSrc})` }}
// 					></div>
// 					<div
// 						className="content2-back back"
// 						style={{ backgroundImage: `url(${logoBackSrc})` }}
// 					>
// 						<Button className="custom-btn">{buttonText}</Button>
// 					</div>
// 				</div>
// 			</Link>
// 		</Col>
// 	);
// };

// const HomeCompanies = () => {
// 	return (
// 		<div className="home-companies-container" >
// 			<header className="header">
// 				<img src={logo} alt="Logo" className="logo" />
// 			</header>
// 			<Row justify="center" gutter={[16,16]} >
// 				<CompanyCard
// 					logoFrontSrc={sunsymbole}
// 					logoBackSrc={sunlogo}
// 					buttonText="Connexion"
// 					buttonLink="/login"
// 				/>
// 				<CompanyCard
// 					logoFrontSrc={expertLogo}
// 					logoBackSrc={expertBack}
// 					buttonText="Connexion"
// 					buttonLink="/expertinout"
// 				/>
// 				<CompanyCard
// 					logoFrontSrc={gysymbol}
// 					logoBackSrc={gy}
// 					buttonText="Connexion"
// 					buttonLink="/gandy-invest"
// 				/>
// 				<CompanyCard
// 					logoFrontSrc={servisymbol}
// 					logoBackSrc={servihome}
// 					buttonText="Connexion"
// 					buttonLink="/servihome"
// 				/>
// 				<CompanyCard
// 					logoFrontSrc={optisymbole}
// 					logoBackSrc={optinergy}
// 					buttonText="Connexion"
// 					buttonLink="/optinergy"
// 				/>
// 				<CompanyCard
// 					logoFrontSrc={optisymbole}
// 					logoBackSrc={optinergy}
// 					buttonText="Connexion"
// 					buttonLink="/brandpartners"
// 				/>
// 				<CompanyCard
// 					logoFrontSrc={optisymbole}
// 					logoBackSrc={optinergy}
// 					buttonText="Connexion"
// 					buttonLink="/wizoo"
// 				/>
// 				<CompanyCard
// 					logoFrontSrc={optisymbole}
// 					logoBackSrc={optinergy}
// 					buttonText="Connexion"
// 					buttonLink="/brandassurance"
// 				/>
// 			</Row>
// 		</div>
// 	);
// };

// export default HomeCompanies;


import React from "react";
import "./HomeCompanies.css";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "antd";
import logo from "../../assets/images/logo orange.png";
import gy from "../../assets/images/gy.png";
import expert from "../../assets/images/expert.png";
import servihome from "../../assets/images/servihome.png";
import sunlogo from "../../assets/images/R.png";
import sunsymbole from "../../assets/images/sunlogo.png";
import servisymbol from "../../assets/images/servisymbol.png";
import optinergy from "../../assets/images/optinergy.png";
import optisymbole from "../../assets/images/optisymbole.png";
import gysymbol from "../../assets/images/gysymbol1.png";
// import { Link } from "react-router-dom";
// import { Row, Col, Button } from "antd";

const CompanyCard = ({ number, name, logoFrontSrc, logoBackSrc, buttonText, buttonLink, byText }) => {
	return (
		<Col xs={24} sm={12} md={12} lg={8}>
			<Link to={buttonLink} className="card-link2">
				<div className="card2">
					<div className="content2">
						<img src={logoFrontSrc} alt="Front Logo" />
						{byText && (
							<div className="by-text">
								<span className="by-big">BY</span>
								<span className="by-small">Brand partners</span>
							</div>
						)}
					</div>
					<div className="content2-back">
						<img src={logoBackSrc} alt="Back Logo" />
						<div className="button-container">
							<Button className="custom-btn">{buttonText}</Button>
						</div>
					</div>
					<div className="glow2"></div>
				</div>
			</Link>
		</Col>
	);
};



const HomeCompanies = () => {
	return (
		<div>
			<header className="header">
				<img src={logo} alt="Logo" className="logo" />
			</header>
			<Row gutter={[16, 16]} justify="center" style={{ margin: "0 80px" }}>
				<CompanyCard
					number="01"
					name="Sunlight-Call PRD"
					logoFrontSrc={sunsymbole}
					logoBackSrc={sunlogo}
					buttonText="Connexion"
					buttonLink="/login"
					byText={true}
				/>


				<CompanyCard
					number="02"
					name="Expertinout"
					logoFrontSrc={expert}
					logoBackSrc={expert}
					buttonText="Connexion"
					buttonLink="/expertinout"
				/>
				<CompanyCard
					number="03"
					name="gandy invest"
					logoFrontSrc={gysymbol}
					logoBackSrc={gy}
					buttonText="Connexion"
					buttonLink="/gandy-invest"
				/>
				<CompanyCard
					number="04"
					name="Servihome"
					logoFrontSrc={servisymbol}
					logoBackSrc={servisymbol}
					buttonText="Connexion"
					buttonLink="/servihome"
				/>
				<CompanyCard
					number="05"
					name="optinergy"
					logoFrontSrc={optisymbole}
					logoBackSrc={optisymbole}
					buttonText="Connexion"
					buttonLink="/optinergy"
				/>

			</Row>
		</div>
	);
};

export default HomeCompanies;
