import React, { useState, useEffect, useRef } from "react";
import {
	Form,
	Input,
	DatePicker,
	TimePicker,
	Row,
	Col,
	Card,
	ConfigProvider,
	Select,
	Radio,
	message,
	Alert,
} from "antd";
import frFR from "antd/lib/locale/fr_FR";
import SaveButton from "../../../constants/SaveButton";
import SupprimerButton from "../../../constants/SupprimerButton";
import { handleFormSubmit, handleDelete } from '../services/api'
import moment from "moment";
import { useAuth } from "../../../AuthContext";
const { Option } = Select;

const UpdateRdv = ({ initialValues, onFormSubmit, setShowUpdateModal }) => {
	const [form] = Form.useForm();
	const [loadingEnregistrer, setLoadingEnregistrer] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const secondCardRef = useRef(null);
	const [secondCardHeight, setSecondCardHeight] = useState('auto');
	const { userRole, userId } = useAuth();

	const [formData, setFormData] = useState({
		title: "",
		nom: "",
		prenom: "",
		nom_ste: "",
		postal: "",
		adresse: "",
		porte: "",
		tva: "",
		tel: "",
		gsm: "",
		fournisseur: "",
		nbr_comp_elect: "",
		nbr_comp_gaz: "",
		ppv: null,
		tarif: null,
		pro: null,
		haute_tension: null,
		tarification: "",
		commentaire: "",
		note: "",
		bloquer: false,
		appointment_date: [moment(initialValues.start_date), moment(initialValues.end_date)], // Initialize with moment objects
	});

	useEffect(() => {
		if (secondCardRef.current) {
			const height = secondCardRef.current.clientHeight;
			setSecondCardHeight(height);
		}
	}, [formData.pro]);

	const rdvList = initialValues.rdv || initialValues;

	useEffect(() => {
		if (rdvList && Object.keys(rdvList).length > 0) {

			const formattedInitialValues = {
				...rdvList,
				nom_prenom: `${rdvList.nom}${rdvList.prenom && rdvList.prenom.toLowerCase() != 'null' ? ` ${rdvList.prenom}` : ''}`,
				startTime: moment(rdvList.start_date),
				endTime: moment(rdvList.end_date),
				ppv: rdvList.ppv == 1 ? 1 : 0,
				bloquer: Boolean(rdvList.bloquer),
				pro: rdvList.pro == 1 ? 1 : 0,
				tarif: rdvList.tarif == 1 ? 1 : 0,
				haute_tension: rdvList.haute_tension == 1 ? 1 : 0,
				tarification: rdvList.tarification === "Variable" ? true : false,
				commentaire: rdvList.commentaire,
				note: rdvList.note,
			};
			console.log('rdvList', rdvList)

			console.log('formattedInitialValues', formattedInitialValues)
			form.setFieldsValue(formattedInitialValues);
			setFormData({
				...formattedInitialValues,
				appointment_date: [moment(rdvList.start_date), moment(rdvList.end_date)], // Update appointment_date with moment objects
			});
		}
	}, [rdvList, form]);


	const getFutureWeekdays = () => {
		if (userRole === "Agent") {
			const today = moment();
			const tomorrow = today.clone().add(1, 'days');
			const futureWeekdays = [];
			let count = 0;

			while (futureWeekdays.length < 3) {
				const nextDay = tomorrow.clone().add(count, 'days');

				if (nextDay.day() !== 6 && nextDay.day() !== 0) {
					futureWeekdays.push(nextDay);
				}

				count++;
			}

			return futureWeekdays;
		}
		else {
			const today = moment();
			const futureDates = [];
			let count = 0;

			while (true) {
				const nextDay = today.clone().add(count, 'days');

				if (nextDay.day() !== 6 && nextDay.day() !== 0) {
					futureDates.push(nextDay);
				}


				if (futureDates.length >= 200) {
					break;
				}

				count++;
			}

			return futureDates;
		}
	};


	// Handle start date change
	const handleStartDateChange = (date, dateString) => {
		const newStartDate = moment(date);
		const newEndDate = newStartDate.clone().add(1, 'hours');

		const newAppointmentDate = [...formData.appointment_date];
		newAppointmentDate[0] = newStartDate;
		newAppointmentDate[1] = newEndDate;

		setFormData(prevState => ({
			...prevState,
			appointment_date: newAppointmentDate,
		}));
	};

	const handleStartTimeChange = (time, timeString) => {
		const { appointment_date } = formData;
		const newStartDate = moment(appointment_date[0]).set({
			hour: time.hour(),
			minute: time.minute(),
		});
		const newEndDate = newStartDate.clone().add(1, 'hours');

		const newAppointmentDate = [newStartDate, newEndDate];

		setFormData(prevState => ({
			...prevState,
			appointment_date: newAppointmentDate,
		}));
	};

	// Handle end time change for non-Agent users
	const handleEndTimeChange = (time, timeString) => {
		if (userRole !== "Agent") {
			const newEndDate = moment(formData.appointment_date[1]).set({
				hour: time.hour(),
				minute: time.minute(),
			});

			const newAppointmentDate = [...formData.appointment_date];
			newAppointmentDate[1] = newEndDate;

			setFormData(prevState => ({
				...prevState,
				appointment_date: newAppointmentDate,
			}));
		}
	};

	// Disable end date if user role is Agent
	const isEndDateDisabled = () => {
		return userRole === "Agent";
	};

	// Set end date automatically if user role is Agent
	const handleEndDateChange = (date, dateString) => {
		if (userRole === "Agent") {
			const newEndDate = moment(date);
			const newStartDate = newEndDate.clone().subtract(1, 'hours');

			const newAppointmentDate = [...formData.appointment_date];
			newAppointmentDate[0] = newStartDate;
			newAppointmentDate[1] = newEndDate;

			setFormData(prevState => ({
				...prevState,
				appointment_date: newAppointmentDate,
			}));
		} else {
			const newEndDate = moment(date);

			const newAppointmentDate = [...formData.appointment_date];
			newAppointmentDate[1] = newEndDate;

			setFormData(prevState => ({
				...prevState,
				appointment_date: newAppointmentDate,
			}));
		}
	};

	const handleFormFinish = () => {
		handleFormSubmit(
			formData,
			rdvList,
			userId,
			onFormSubmit,
			setShowUpdateModal,
			message,
			setLoadingEnregistrer,
			setShowAlert
		);
	};

	const handleDeleteClick = () => {
		handleDelete(
			rdvList,
			setShowUpdateModal,
			setLoadingDelete,
		);
	};

	return (
		<Form layout="vertical" form={form} onFinish={handleFormFinish}>
			{showAlert && (
				<Alert
					message="La date sélectionnée est déjà réservée."
					type="warning"
					showIcon
					closable
					onClose={() => setShowAlert(false)}
				/>
			)}
			<Card style={{ marginBottom: "10px" }}>
				<Row justify="end" gutter={[16, 16]} style={{ marginBottom: "10px" }}>
					<Col>
						<SaveButton
							loading={loadingEnregistrer}
							buttonText="Enregistrer"
						/>
					</Col>
					{rdvList.bloquer == 1 ? (
						<Col>
							<SupprimerButton

								loading={loadingDelete}
								onClick={handleDeleteClick}
								buttonText="Supprimer"
							/>
						</Col>
					) : (
						null
					)}
				</Row>
			</Card>
			<Card style={{ marginBottom: "10px" }}>
				<Row gutter={[16, 16]} style={{ alignItems: "center", textAlign: "center", marginLeft: "5px" }}>
					<>
						<span>Date début</span>
						<Col span={6}>
							<ConfigProvider locale={frFR}>
								<DatePicker
									allowClear={false}
									value={formData.appointment_date[0]}
									onChange={(date, dateString) => handleStartDateChange(date, dateString)}
									format="YYYY-MM-DD"
									// disabledDate={disabledDateForAgent}
									disabledDate={(current) => {

										const futureDates = getFutureWeekdays();
										return !futureDates.some(date => current.isSame(date, 'day'));
									}}
								/>
							</ConfigProvider>
						</Col>
						<Col span={3}>
							<ConfigProvider locale={frFR}>
								<TimePicker
									allowClear={false}

									value={formData?.appointment_date[0]}
									onChange={(time, timeString) => handleStartTimeChange(time, timeString)}
									format="HH:mm"
									minuteStep={15}
									disabledHours={() => {
										const disabledHours = [];

										for (let i = 0; i < 9; i++) {
											disabledHours.push(i);
										}
										for (let i = 20; i < 24; i++) {
											disabledHours.push(i);
										}
										return disabledHours;
									}}
								/>
							</ConfigProvider>
						</Col>
					</>

					<>
						<span>Date fin</span>

						<Col span={6}>
							<ConfigProvider locale={frFR}>
								<DatePicker
									allowClear={false}

									value={formData?.appointment_date[1]}
									onChange={(date, dateString) => handleEndDateChange(date, dateString)}
									format="YYYY-MM-DD"
									disabledDate={(current) => {

										const futureDates = getFutureWeekdays();
										return !futureDates.some(date => current.isSame(date, 'day'));
									}}
									disabled={isEndDateDisabled()}

								/>
							</ConfigProvider>
						</Col>
						<Col span={3}>
							<ConfigProvider locale={frFR}>
								<TimePicker
									allowClear={false}

									value={formData.appointment_date[1]}
									onChange={(time, timeString) => handleEndTimeChange(time, timeString)}
									format="HH:mm"
									minuteStep={15}
									disabledHours={() => {
										const disabledHours = [];
										for (let i = 0; i < 9; i++) {
											disabledHours.push(i);
										}
										for (let i = 20; i < 24; i++) {
											disabledHours.push(i);
										}
										return disabledHours;
									}}
									disabled={isEndDateDisabled()}

								/>
							</ConfigProvider>
						</Col>
					</>
				</Row>
			</Card>


			<Row gutter={[16, 16]}>
				<Col span={16}>
					<Card style={{ height: formData.pro == 1 ? 'auto' : secondCardHeight }}>
						<Row gutter={[16, 16]}>
							<Col span={24}>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<Form.Item
											label="Êtes-vous un professionnel ?"
											name="pro"
											rules={[
												{
													required: true,
													message: "Veuillez sélectionner votre statut professionnel !",
												},
											]}
										>
											<Radio.Group
												onChange={(e) => {
													setFormData({
														...formData,
														pro: e.target.value,
													});
													if (e.target.value) {
														form.setFieldsValue({ nom_ste: "", tva: "" });
													}

												}}
											>

												<Radio value={1}>Oui</Radio>
												<Radio value={0}>Non</Radio>
											</Radio.Group>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											label="Nom et Prénom"
											name="nom_prenom"
											rules={[
												{
													required: true,
													message: "Veuillez entrer votre nom et prénom !",
												},
											]}
										>
											<Input
												onChange={(e) => {
													const trimmedValue = e.target.value.trim();
													setFormData({
														...formData,
														nom_prenom: trimmedValue === "" ? null : trimmedValue,
													});
												}}
											/>
										</Form.Item>
									</Col>

								</Row>
							</Col>

							<Col span={24}>
								<Row gutter={[16, 16]}>
									{formData.pro == 1 && (
										<>
											<Col span={24}>
												<Row gutter={[16, 16]}>
													<Col xs={24} sm={12} lg={12}>
														<Form.Item
															label="Nom de Société"
															name="nom_ste"
															initialValue={rdvList?.nom_ste}

														>
															<Input
																onChange={(e) =>
																	setFormData({
																		...formData,
																		nom_ste: e.target.value,
																	})
																}
															/>
														</Form.Item>
													</Col>
													<Col xs={24} sm={12} lg={12}>
														<Form.Item
															label="TVA"
															name="tva"
															value={rdvList.tva ? rdvList?.tva.replace(/^BE0/, '') : ''}
															rules={[

																{
																	validator: (_, value) => {
																		const regex = /^\d{9}$/;
																		if (value && !regex.test(value)) {
																			return Promise.reject(
																				'Le numéro de TVA doit commencer par "BE0" suivi de 9 chiffres.'
																			);
																		}
																		return Promise.resolve();
																	},
																},
															]}
														>
															<Input
																maxLength={9}
																prefix="BE  0"
																onChange={(e) =>
																	setFormData({
																		...formData,
																		tva: e.target.value,
																	})
																}
															/>
														</Form.Item>
													</Col>
												</Row>
											</Col>
										</>
									)}
								</Row>
							</Col>

							<Col span={24}>
								<Row gutter={[16, 16]}>
									<Col lg={24} md={11} sm={24}>
										<Form.Item
											label="Rue"
											name="adresse"
											rules={[
												{
													required: true,
													message:
														"Veuillez entrer votre adresse !",
												},
											]}
										>
											<Input
												onChange={(e) =>
													setFormData({
														...formData,
														adresse: e.target.value,
													})
												}
											/>
										</Form.Item>

									</Col>
									<Col lg={12} md={11} sm={24}>
										<Form.Item
											label="Porte"
											name="porte"
											rules={[
												{
													required: true,
													message: "Veuillez entrer votre porte !",
												},
												{
													pattern: /^[0-9]*$/,
													message: "Veuillez entrer uniquement des chiffres.",
												},
											]}

										>
											<Input
												onChange={(e) =>
													setFormData({
														...formData,
														porte: e.target.value,
													})
												}
											/>
										</Form.Item>

									</Col>
									<Col xs={24} sm={12} lg={12}>
										<Form.Item
											label="Code Postal"
											name="postal"
											initialValue={rdvList?.postal}
											rules={[
												{
													required: true,
													message:
														"Veuillez entrer votre code postal !",
												},
												{
													pattern: /^\d{4}$/, // Regex pattern to match exactly 4 digits
													message:
														"Veuillez entrer un code postal valide (4 chiffres).",
												},
											]}
										>
											<Input
											maxLength={4}

												onChange={(e) =>
													setFormData({
														...formData,
														postal: e.target.value,
													})
												}
											/>
										</Form.Item>
									</Col>
								</Row>
							</Col>
							<Col span={24}>
								<Row gutter={[16, 16]}>
									<Col xs={24} sm={12} lg={12}>
										<Form.Item
											name="tel"
											label="Téléphone"
											initialValue={rdvList?.tel}
											rules={[
												{
													required: true,
													message:
														"Veuillez saisir votre numéro de téléphone!",
												},
												{
													pattern: /^\d{8}$/, // Regex pattern to match +32 followed by 8 digits
													message:
														"Veuillez saisir un numéro de téléphone valide de 8 chiffres (ex: +32123456789).",
												},
											]}
										>
											<Input
												maxLength={8}
												prefix={
													<span
														style={{
															padding: "0 8px",
														}}
													>
														+32
													</span>
												}
												style={{ width: "100%" }}
												onChange={(e) =>
													setFormData({
														...formData,
														tel: e.target.value,
													})
												}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={12} lg={12}>
										<Form.Item
											label="GSM"
											name="gsm"
											initialValue={rdvList?.gsm}
											rules={[

												{
													pattern: /^\d{8}$/,
													message:
														"Veuillez saisir un numéro de GSM valide de 8 chiffres(ex: +32412345678).",
												},
											]}
										>

											<Input
												maxLength={8}
												prefix={
													<span
														style={{
															padding: "0 8px",
														}}
													>
														+324
													</span>
												}
												style={{ width: "100%" }}
												onChange={(e) =>
													setFormData({
														...formData,
														gsm: e.target.value,
													})
												}
											/>
										</Form.Item>
									</Col>
								</Row>
							</Col>
							<Col span={24}>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<Form.Item
											label="Nombre de Compteurs Électriques"
											name="nbr_comp_elect"
											initialValue={
												rdvList?.nbr_comp_elect
											}
											rules={[
												{
													required: true,
													message:
														"Veuillez sélectionner le nombre de compteurs de gaz !",
												},
											]}
										>
											<Select
												onChange={(value) =>
													setFormData({
														...formData,
														nbr_comp_elect: value,
													})
												}
												placeholder="Sélectionner le nombre de compteurs électriques"
											>
												<Select.Option value="0">
													0
												</Select.Option>
												<Select.Option value="1">
													1
												</Select.Option>
												<Select.Option value="2">
													2
												</Select.Option>
												<Select.Option value="3">
													3
												</Select.Option>
												<Select.Option value="4">
													4
												</Select.Option>
												<Select.Option value="+4">
													+4
												</Select.Option>
											</Select>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											label="Nombre de Compteurs Gaz"
											name="nbr_comp_gaz"
											initialValue={
												rdvList?.nbr_comp_gaz
											}
											rules={[
												{
													required: true,
													message:
														"Veuillez sélectionner le nombre de compteurs de gaz !",
												},
											]}
										>
											<Select
												onChange={(value) =>
													setFormData({
														...formData,
														nbr_comp_gaz: value,
													})
												}
												placeholder="Sélectionner le nombre de compteurs gaz"
											>
												<Select.Option value="0">
													0
												</Select.Option>
												<Select.Option value="1">
													1
												</Select.Option>
												<Select.Option value="2">
													2
												</Select.Option>
												<Select.Option value="3">
													3
												</Select.Option>
												<Select.Option value="4">
													4
												</Select.Option>
												<Select.Option value="+4">
													+4
												</Select.Option>
											</Select>
										</Form.Item>
									</Col>
									<Col span={24}>
										<Form.Item
											label="Commentaire"
											name="commentaire"
										>
											<Input.TextArea rows={3} onChange={(e) =>
												setFormData({
													...formData,
													commentaire: e.target.value,
												})} />
										</Form.Item>
									</Col>
								</Row>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={8}>
					<Card ref={secondCardRef}>
						<Row gutter={[16, 16]}>
							<Col span={24}>
								<Form.Item
									name="fournisseur"
									label="Fournisseur"
									initialValue={rdvList?.fournisseur}
									rules={[
										{
											required: true,
											message:
												"Veuillez sélectionner votre fournisseur actuel!",
										},
									]}
								>
									<Select
										placeholder="Sélectionner votre fournisseur"
										onChange={(value) =>
											setFormData({
												...formData,
												fournisseur: value,
											})
										}
									>
										{[
											"Luminus",
											"Mega",
											"OCTA+",
											"Eneco",
											"TotalEnergies",
											"Aspiravi Energy",
											"Bolt",
											"COCITER",
											"DATS 24",
											"EBEM",
											"Ecopower",
											"Elegant",
											"Energie.be",
											"Frank Energie",
											"Trevion",
											"Wind voor A",
										].map((fournisseur, index) => (
											<Option
												key={index}
												value={fournisseur}
											>
												{fournisseur}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									label="PPV"
									name="ppv"
									rules={[
										{
											required: true,
											message: "Veuillez sélectionner Oui ou Non pour PPV !",
										},
									]}
								>
									<Radio.Group
										onChange={(e) =>
											setFormData({
												...formData,
												ppv: e.target.value,
											})
										}
									>
										<Radio value={1}>Oui</Radio>
										<Radio value={0}>Non</Radio>
									</Radio.Group>
								</Form.Item>



							</Col>
							<Col span={24}>
								<Form.Item
									label="Tarif Social"
									name="tarif"
									rules={[
										{
											required: true,
											message:
												"Veuillez sélectionner si vous avez un tarif social ou non !",
										},
									]}
								>
									<Radio.Group
										onChange={(e) => {
											setFormData({
												...formData,
												tarif: e.target.value,
											});
										}}


									>
										<Radio value={1} >Oui</Radio>
										<Radio value={0}>Non</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									label="Haute Tension"
									name="haute_tension"
									rules={[
										{
											required: true,
											message:
												"Veuillez sélectionner si vous êtes en haute tension ou non !",
										},
									]}
								>
									<Radio.Group
										onChange={(e) =>
											setFormData({
												...formData,
												haute_tension: e.target.value,
											})
										}

									>
										<Radio value={1}>Oui</Radio>
										<Radio value={0}>Non</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									label="Tarification"
									name="tarification"
									rules={[
										{
											required: true,
											message:
												"Veuillez sélectionner votre type de tarification !",
										},
									]}
								>
									<Radio.Group
										onChange={(e) =>
											setFormData({
												...formData,
												tarification: e.target.value,
											})
										}
									>
										<Radio value={true}>Variable</Radio>
										<Radio value={false}>Fixe</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item label="Note" name="note">
									<Input.TextArea rows={3} onChange={(e) =>
										setFormData({
											...formData,
											note: e.target.value,
										})
									} />
								</Form.Item>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</Form>
	);
};

export default UpdateRdv;
