import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'primeicons/primeicons.css';
import { Spin } from "antd";
import { AuthProvider } from "./AuthContext";
import Home from "./pages/Dashboard/Home";
import Tables from "./pages/rdv/views/Tables";
import SignIn from "./pages/login/Login";
import Main from "./components/layouts/Main";
import "antd/dist/antd.min.css";
import AddUserForm from "./pages/user/addUser/AddUserForm";
import Contacts from "./pages/contacts/views/Contacts";
import { SidebarProvider } from "./SidebarContext";
import Agenda from "./pages/agenda/views/DisplayAgenda";
import HomeCompanies from "./pages/home/HomeCompanies";
import DisplayUsers from "./pages/user/views/UsersTable";
import AgentChart from "./pages/Dashboard/AgentChart/AgentChart"
import AgentCommercialCal from './pages/AC/views/AgentCommercialCal'
import { CalendarProvider } from "./CalendarContext";
import { UserProvider } from "./GlobalContext";
import NotAuthorizedPage from "./pages/NotAuthorizedPage";
import NotFoundPage from "./pages/NotFoundPage";


import PrivateRoute from "./PrivateRoute";
import ACRdv from "./pages/AC/ACtable/ACRdv"
import ACDashboard from "./pages/AC/ACDashboard/ACDashboard";
import RdvDetails from "./pages/Dashboard/RdvDetail/RdvDetails";
import RdvDetail from "./pages/caledrier/rdv details/RdvDetail";
import Calendar from "./pages/caledrier/views/Calendar";
import HistoriqueRdvSupprimer from "./pages/Dashboard/RdvSupprimerHistoriaue/HistoriqueRdvSupprimer";
import DeletedUsers from "./pages/Dashboard/UserSuprimer/DeletedUsers";
import UserAppointments from "./pages/Dashboard/UserRdv/UserAppointments";
import History from "./pages/Dashboard/history/History";
import StatistiquesMois from "./pages/Dashboard/statistics/StatistiquesMois";
import StatistiquesDemain from "./pages/Dashboard/statistics/StatistiquesDemain";
import BackupFilesPage from "./components/layouts/BackupFilesPage";
import AgentAppointments from "./pages/Dashboard/agent/AgentRdvTable";


function App() {
	const [loading, setLoading] = useState(true);


	setTimeout(() => {
		setLoading(false);
	}, 2000);

	return (
		<Router>
			<UserProvider>
				<CalendarProvider>
					<AuthProvider>
						<SidebarProvider>
							<div className="App">
								{loading ? (
									<div style={{ textAlign: "center", paddingTop: "50vh" }}>
										<Spin size="large" />
									</div>
								) : (
									<Switch>
										<Route path="/login" exact component={SignIn} />
										<Route path="/" exact component={HomeCompanies} />
										<Route path="/not-authorized" component={NotAuthorizedPage} />
										<Route path="/not-found" component={NotFoundPage} />


										<Main>

											<PrivateRoute exact path="/dashboard" component={Home} allowedRoles={["Admin", "Superviseur", "Agent Commercial", "superadmin"]} />
											<Route exact path="/calendrier" component={Calendar} />
											<Route exact path="/AC-calendrier" component={AgentCommercialCal} allowedRoles={["Admin", "Agent Commercial"]} />
											<PrivateRoute exact path="/rdv" component={Tables} allowedRoles={["Admin", "Superviseur", "superadmin"]} />
											<PrivateRoute exact path="/historiques-rdv-modifiés" component={History} allowedRoles={["Admin", "Superviseur", "superadmin"]} />
											<PrivateRoute exact path="/statistiques-rdv-du-mois" component={StatistiquesMois} allowedRoles={["Admin", "Superviseur", "superadmin"]} />
											<PrivateRoute exact path="/statistiques-rdv-pour-demain" component={StatistiquesDemain} allowedRoles={["Admin", "Superviseur", "superadmin"]} />
											<PrivateRoute exact path="/historique-rdv-supprimé" component={HistoriqueRdvSupprimer} allowedRoles={["Admin", "Superviseur", "superadmin"]} />
											<PrivateRoute exact path="/agent-statistiques" component={AgentChart} allowedRoles={["Admin", "Superviseur", "superadmin"]} />
											<PrivateRoute exact path="/historique-utilisateurs-supprimé" component={DeletedUsers} allowedRoles={["Admin", "Superviseur", "superadmin"]} />
											<PrivateRoute exact path="/contact" component={Contacts} allowedRoles={["Admin", "Superviseur", "superadmin"]} />
											<PrivateRoute exact path="/agenda" component={Agenda} allowedRoles={["Admin", "Superviseur", "superadmin"]} />
											<PrivateRoute exact path="/creer-utilisateur" component={AddUserForm} allowedRoles={["Admin", "Superviseur", "superadmin"]} />
											<PrivateRoute exact path="/utilisateurs" component={DisplayUsers} allowedRoles={["Admin", "Superviseur", "superadmin"]} />
											<PrivateRoute exact path="/agent-calendrier" component={DisplayUsers} allowedRoles={["Admin", "Superviseur", "superadmin"]} />
											<PrivateRoute exact path="/details-rdv-modifie/:id" component={RdvDetails} allowedRoles={["Admin", "Superviseur", "superadmin"]} />
											<PrivateRoute exact path="/rendez-vous-utilisateur/:userId" component={UserAppointments} allowedRoles={["Admin", "Superviseur", "superadmin"]} />
											<PrivateRoute exact path="/AC-dashboard" component={ACDashboard} allowedRoles={["Admin", "Superviseur", "Agent Commercial", "superadmin"]} />
											<PrivateRoute exact path="/liste-rdv" component={ACRdv} allowedRoles={["Admin", "Superviseur", "Agent Commercial", "superadmin"]} />
											<PrivateRoute exact path="/rdv-detail/:id" component={RdvDetail} allowedRoles={["Admin", "Superviseur", "Agent Commercial", "Agent", "superadmin"]} />
											<PrivateRoute exact path="/backup-files" component={BackupFilesPage} allowedRoles={["Admin", "Superviseur", "superadmin"]} />
											<PrivateRoute exact path="/agent-rdvs-table" component={AgentAppointments} allowedRoles={["Agent"]} />

										</Main>
										
									</Switch>

								)}

							</div>
						</SidebarProvider>
					</AuthProvider>
				</CalendarProvider>
			</UserProvider>
		</Router>
	);
}

export default App;
