import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Col, Row, Card, Select, Avatar } from "antd";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import SaveButton from "../../../constants/SaveButton";
import "../views/user.css";
import { useCalendar } from "../../../CalendarContext";
import { useAuth } from '../../../AuthContext';
import { handleFileUpload, handleSubmit } from "../services/api";

const roleOptions = [
	{ value: "Admin", label: "Admin" },
	{ value: "Agent", label: "Agent" },
	{ value: "Superviseur", label: "Superviseur" },
	{ value: "Agent Commercial", label: "Agent Commercial" },
	{ value: "Confirmateur", label: "Confirmateur" }, 
];


const AddUserForm = () => {
	const [form] = Form.useForm();
	const [formData, setFormData] = useState({
		nom: "",
		prenom: "",
		email: "",
		role: "",
		password: "",
		confirmPassword: "",
		image: null,
	});
	const [loading, setLoading] = useState(true);
	const [imagePreview, setImagePreview] = useState(null);
	const history = useHistory();
	const { appointments } = useCalendar();
	const { userRole } = useAuth();

	useEffect(() => {
		const timeout = setTimeout(() => {
			setLoading(false);
		}, 1000);

		return () => clearTimeout(timeout);
	}, []);

	const handleChange = (changedValues) => {
		setFormData({
			...formData,
			...changedValues,
		});
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const getFilteredRoleOptions = () => {
		if (userRole === "superadmin") {
			return roleOptions;
		}
		return roleOptions.filter(option => userRole === "Admin" || option.value !== "Admin");
	};

	return (
		<div>
			<Card>
				<Form
					form={form}
					layout="vertical"
					initialValues={formData}
					onValuesChange={handleChange}
					
					style={{ padding: "30px" }}
				>
					<Row justify="center" gutter={[16, 16]} align={"middle"}>
						<Col xs={24} lg={6}>
							<Form.Item label="Photo de profil" rules={[{ required: true, message: "Veuillez choisir une photo!" }]}>
								{imagePreview ? (
									<img src={imagePreview} alt="Preview" style={{ width: "60%", borderRadius: "8px" }} />
								) : (
									<Avatar size={120} icon={<UserOutlined />} />
								)}
								<div style={{ marginTop: "10px" }}>
									<button
										type="button"
										onClick={() => document.getElementById('file-upload').click()}
										style={{
											padding: "6px 12px",
											borderRadius: "4px",
											border: "1px solid #d9d9d9",
											cursor: "pointer",
											width: "60%",
											background: "white",
											display: "block"
										}}
									>
										Choose File
									</button>
									<input
										id="file-upload"
										type="file"
										name="image"
										onChange={(e) => handleFileUpload(e, setFormData, setImagePreview, formData)}
										style={{
											display: "none"
										}}
									/>
								</div>
							</Form.Item>
						</Col>

						<Col xs={24} md={12} lg={8}>
							<Row gutter={[16, 16]}>
								<Col span={24}>
									<Form.Item
										name="nom"
										label="Nom"
										rules={[{ required: true, message: "Veuillez entrer votre nom!" }]}
									>
										<Input
											prefix={<UserOutlined />}
											placeholder="Nom"
											name="nom"
											value={formData.nom}
											onChange={handleInputChange}
										/>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item
										name="prenom"
										label="Prénom"
										rules={[{ required: true, message: "Veuillez entrer votre prénom!" }]}
									>
										<Input
											prefix={<UserOutlined />}
											placeholder="Prénom"
											name="prenom"
											value={formData.prenom}
											onChange={handleInputChange}
										/>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item
										name="email"
										label="Email"
										rules={[{ required: true, message: "Veuillez entrer votre email!" }]}
									>
										<Input
											prefix={<MailOutlined />}
											type="email"
											placeholder="Email"
											name="email"
											value={formData.email}
											onChange={handleInputChange}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Col>
						<Col xs={24} md={12} lg={8}>
							<Row gutter={[16, 16]}>
								<Col span={24}>
									<Form.Item
										name="role"
										label="Rôle"
										rules={[{ required: true, message: "Veuillez choisir un rôle!" }]}
									>
										<Select
											placeholder="Sélectionner un rôle"
											options={getFilteredRoleOptions()}
											value={formData.role}
											onChange={(value) =>
												setFormData({
													...formData,
													role: value,
												})
											}
										/>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item
										name="password"
										label="Mot de passe"
										rules={[{ required: true, message: "Veuillez entrer votre mot de passe!" }]}
									>
										<Input.Password
											prefix={<LockOutlined />}
											placeholder="Mot de passe"
											name="password"
											value={formData.password}
											onChange={handleInputChange}
										/>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item
										name="confirmPassword"
										label="Confirmer le mot de passe"
										dependencies={["password"]}
										rules={[
											{ required: true, message: "Veuillez confirmer votre mot de passe!" },
											({ getFieldValue }) => ({
												validator(_, value) {
													if (!value || getFieldValue("password") == value) {
														return Promise.resolve();
													}
													return Promise.reject(
														new Error("Les deux mots de passe ne correspondent pas!")
													);
												},
											}),
										]}
									>
										<Input.Password
											prefix={<LockOutlined />}
											placeholder="Confirmer le mot de passe"
											name="confirmPassword"
											value={formData.confirmPassword}
											onChange={handleInputChange}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col span={24} style={{ textAlign: "right" }}>
							<SaveButton loading={loading} buttonText="Enregistrer" onClick={() => handleSubmit(formData, form, setImagePreview, history, appointments)}/>
						</Col>
					</Row>
				</Form>

			</Card>
		</div>
	);
};

export default AddUserForm;
