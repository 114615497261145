import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Tag, message } from 'antd';
import { useAuth } from '../../../AuthContext';
import { axiosClient } from '../../../api/axios';
import { EyeOutlined } from "@ant-design/icons";
import RdvDetail from '../../rdv/Store/RdvDetail';
import moment from 'moment';

const AgentAppointments = () => {
	const [appointments, setAppointments] = useState([]);
	const [loading, setLoading] = useState(false);
	const [detailsModalVisible, setDetailsModalVisible] = useState(false);
	const [selectedRowData, setSelectedRowData] = useState(null);
	const { userId } = useAuth()

	useEffect(() => {
		const fetchAppointments = async () => {
			setLoading(true);
			try {
				const response = await axiosClient.get(`rdvs/agent/${userId}`);
				console.log('rdv agent', response.data)
				setAppointments(response.data);
				setLoading(false);
			} catch (error) {
				console.log('error fetching rdvs', error.response)
				setLoading(false);
			}
		};

		if (userId) {
			fetchAppointments();
		}
	}, [userId]);
	const handleDetailsClick = (record) => {
		console.log('rec', record)
		setSelectedRowData(record);
		setDetailsModalVisible(true);
	};

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
			width: "10%",
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Agenda',
			render: (text, record) => {
				const user = record.agenda && record.agenda.user;
				return (
					<span>{user ? `${user.nom} ${user.prenom}` : "N/A"}</span>
				);
			},
			key: 'agenda_user',
		},
		{
			title: 'Client',
			dataIndex: "client",

			render: (text, record) => (
				<span>{record.nom} {record.prenom}</span>
			),
			key: 'client',
		},
		{
			title: 'Statut',
			dataIndex: 'status',
			key: 'status',
			render: (status, record) => {
				// Check if the appointment has been deleted (deleted_at exists)
				if (record.deleted_at) {
					return (
						<Tag
							color="#FF4D4F"
							style={{ fontWeight: '400', fontSize: '12px', lineHeight: '1.5', marginBottom: '0' }}
						>
							Annuler
						</Tag>
					);
				}

				// If not deleted, render the usual status
				return (
					<Tag
						color={
							status === 'NRP' ? '#FF8A08' :
								status === 'confirmer' ? '#00E396' :
									status === 'encours' ? '#373A40' :
										'#FFF'
						}
						style={{ fontWeight: '400', fontSize: '12px', lineHeight: '1.5', marginBottom: '0' }}
					>
						{status}
					</Tag>
				);
			},
		},
		{
			title: "TEL",
			dataIndex: "tel",
			key: "tel",
			width: "10%",
			className: "agenda-column",
			render: (text) => (text ? `+32${text}` : "N/A"),
		},
		{
			title: "GSM",
			dataIndex: "gsm",
			key: "gsm",
			width: "10%",
			className: "agenda-column",
			render: (text) => (
				<span style={{ fontSize: '13px' }} >
					{text ? `+32422${text}` : "N/A"}
				</span>
			),
		},
		{
			title: "Postal",
			dataIndex: "postal",
			key: "postal",
			width: "10%",
			className: "agenda-column",
			render: (text) => (text || "N/A"),
		},
		{
			title: 'Date de RDV',
			render: (text, record) => {
				const startDate = moment(record.start_date).format('DD/MM/YYYY HH:mm');
				const endDate = moment(record.end_date).format('HH:mm');
				return <span>{`${startDate} - ${endDate}`}</span>;
			},
			key: 'date_rdv',
		},
		{
			title: "Action",
			key: "action",
			render: (_, record) => (
				<div>
					<Button
						type="link"
						onClick={() => handleDetailsClick(record)}
					>
						<EyeOutlined />
					</Button>
				</div>
			),
		},
	];


	return (
		<div>
			<Card>
				<Table
					className="table-container"

					columns={columns}
					dataSource={appointments}
					loading={loading}
					rowKey="id"
					pagination={{ pageSize: 5 }}
					style={{ fontSize: "12px" }}
				/>

			</Card>

			<Modal
				title="Consulter rendez-vous"
				visible={detailsModalVisible}
				onCancel={() => setDetailsModalVisible(false)}
				footer={null}
				style={{ marginTop: "-50px" }}
				width="80%"
				destroyOnClose
			>
				{selectedRowData && (
					<RdvDetail
						rdv={selectedRowData}
					/>
				)}
			</Modal>
		</div>
	);
};

export default AgentAppointments;
