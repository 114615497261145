import * as XLSX from 'xlsx';
import moment from "moment"
import { axiosClient } from '../../../api/axios';
import { message } from 'antd';


export const handleDownloadAppointment = (appointment) => {
	const { id, nom, prenom, pro, nom_ste, tva, tel, gsm, postal, adresse, porte, fournisseur, tarification,
		nbr_comp_elect, nbr_comp_gaz, ppv, tarif, haute_tension, commentaire, note, start_date, end_date,
		deleted_by, created_at, deleted_at } = appointment;
		const dateDeRendezVous = `${start_date} - ${end_date}`;

	const data = {
		"ID": id,
		"Date de rendez-vous": dateDeRendezVous,
		"Nom Client": prenom ? `${nom} ${prenom}` : nom,
		"Type": pro == 1 ? 'Professionnel' : 'Résidentiel',
		"Société": nom_ste || 'N/A',
		"TVA": tva || 'N/A',
		"TEL": tel ? `+32 ${tel}` : 'N/A',
		"GSM": gsm ? `+324 ${gsm}` : 'N/A',
		"Code Postal": postal,
		"Adresse": adresse,
		"Porte": porte,
		"Fournisseur": fournisseur,
		"Tarification": tarification,
		"Compteurs électriques": nbr_comp_elect,
		"Compteurs gaz": nbr_comp_gaz,
		"PPV": ppv ? 'oui' : 'non',
		"Tarif social": tarif ? 'oui' : 'non',
		"Haute Tension": haute_tension ? 'oui' : 'non',
		"Commentaire": commentaire || 'N/A',
		"Note": note || 'N/A',
		"Date de suppression": deleted_at,
		"Supprimé par": deleted_by ? `${deleted_by.nom} ${deleted_by.prenom}` : 'Inconnu',
		"Date de création": moment(created_at).format("YYYY-MM-DD HH:mm:ss"),
	};

	// Prepare worksheet
	const ws = XLSX.utils.json_to_sheet([data]);
	const wb = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(wb, ws, "Rendez-vous");

	// Trigger download
	XLSX.writeFile(wb, `Rendez-vous${id}.xlsx`);
};

// agent chart api

export const fetchAgents = async () => {
  try {
    const response = await axiosClient.get('users');
    const agents = response.data.users
      .filter(user => user.role === 'Agent')
      .map(user => ({
        id: user.id,
        nom: user?.nom,
        prenom: user?.prenom,
      }));
    return agents;
  } catch (error) {
    console.error('Error fetching agents:', error);
    throw error;
  }
};

export const fetchAppointments = async (selectedAgent, startDate, endDate) => {
  try {
		console.log('params', {
      agentId: parseInt(selectedAgent, 10),
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    })
		console.log('type of selectedAgent ', typeof selectedAgent)
		console.log('type of selectedAgent after parseInt(selectedAgent, 10) ', typeof parseInt(selectedAgent, 10))
    const url = `rdvs/agent-rdv-stats`;
    const params = {
      agentId: parseInt(selectedAgent, 10),
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
		
    const response = await axiosClient.get(url, { params });

    // Directly use the data from the response
    const formattedData = {
      dates: Object.keys(response.data.data),
      counts: Object.values(response.data.data),
    };

    return formattedData;
  } catch (error) {
    console.error('Error fetching appointments:', error);
    throw error;
  }
};

export const fetchAppointments2 = async (selectedAgent, month, year) => {
  try {
    console.log('params', {
      agentId: parseInt(selectedAgent, 10),
      month: month,
      year: year,
    });
    
    const url = `rdvs/agent-rdv-stats-agent`;
    const params = {
      agentId: parseInt(selectedAgent, 10),
      month: month,
      year: year,
    };
    
    const response = await axiosClient.get(url, { params });
		console.log('response', response.data)


    // Directly use the data from the response
    const formattedData = {
      dates: Object.keys(response.data.data),
      counts: Object.values(response.data.data),
    };
		console.log('formattedData', formattedData)

    return formattedData;
  } catch (error) {
    console.error('Error fetching appointments:', error);
    throw error;
  }
};



// Rdv detail

export const fetchData = async (id, setData, setLoading ) => {
	try {
		const response = await axiosClient.get(`rdvs/${id}/modification-data`);
		setData(response.data);
		setLoading(false);
	} catch (error) {
		message.error('Failed to fetch data', error.response);
		setLoading(false);
	}
};

// historique des rdv supprimé

export const fetchDeletedAppointments = async (setAppointments) => {
	try {
			const response = await axiosClient.get('rdvs/rdvs-supprimer');
			const formattedAppointments = response.data.map(appointment => {
					return {
							...appointment,
							start_date: moment(appointment.start_date).format('ddd, DD MMM YYYY HH:mm'),
							deleted_at: moment(appointment.deleted_at).format('ddd, DD MMM YYYY HH:mm'),
							end_date: moment(appointment.end_date).format('HH:mm')
					};
			});
			setAppointments(formattedAppointments);
	} catch (error) {
			console.error('Error fetching deleted appointments:', error);
			return [];
	}
};

export const handleRestoreAppointment = async (id, setAppointments) => {
	try {
			const response = await axiosClient.post(`rdvs/${id}/restore`);
			message.success(response.data.message);

			// Refresh the list of appointments
			const updatedAppointments = await axiosClient.get("rdvs/rdvs-supprimer");
			setAppointments(updatedAppointments.data);
	} catch (error) {
			console.error("Error restoring appointment:", error);
			message.error("Failed to restore appointment");
	}
};

// stat pour demain api

export const fetchAppointmentStatistics = async (setAppointmentStatistics,setRdv) => {
	try {
		console.log('fetchAppointmentStatistics called')
		const response = await axiosClient.get('rdvs/stats');
		setAppointmentStatistics(response.data);
		if (response.data[0]?.hasOwnProperty('appointments')) {
			const appointmentsArray = Object.values(response.data[0].appointments);
			console.log('appointmentsArray', appointmentsArray)
			setRdv(appointmentsArray);
		}
	} catch (error) {
		console.error('Error fetching appointment statistics:', error);
	}
};
