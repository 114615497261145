import React, { useState, useEffect, useRef, useMemo } from "react";
import {
	Form,
	Input,
	DatePicker,
	Row,
	Col,
	Card,
	ConfigProvider,
	Select,
	Radio,
	message,
	Alert,
	Modal,
} from "antd";
import moment from "moment";
import frFR from "antd/lib/locale/fr_FR";
import { axiosClient } from "../../../api/axios";
import SaveButton from "../../../constants/SaveButton";
import { useAuth } from "../../../AuthContext";
import { useCalendar } from "../../../CalendarContext";

const { Option } = Select;

const AddAppointment = ({ onFormSubmit, selectedDate, showAlertAddRdv, setShowAlertAddRdv, setShowAddModal}) => {
	const [showAdditionalInput, setShowAdditionalInput] = useState(false);
	const [formKey, setFormKey] = useState(0);
	const [isRunning, setIsRunning] = useState(false);
	const [formData, setFormData] = useState({
		title: "",
		nom: "",
		prenom: "",
		nom_ste: "",
		postal: "",
		adresse: "",
		porte: "",
		tva: "",
		tel: "",
		gsm: "",
		fournisseur: "",
		pro: null,
		nbr_comp_elect: "",
		nbr_comp_gaz: "",
		ppv: null,
		tarif: null,
		haute_tension: null,
		tarification: null,
		commentaire: "",
		note: "",
		appointment_date: selectedDate
			? [
				new Date(selectedDate.date),
				new Date(selectedDate.date.getTime() + 3600000),
			]
			: null,
	});
	const [loading, setLoading] = useState(false);
	const [selectedDatePickerDate, setSelectedDatePickerDate] = useState(null);
	const secondCardRef = useRef(null);
	const [secondCardHeight, setSecondCardHeight] = useState('auto');
	const { userId } = useAuth()

	useEffect(() => {
		resetForm();
		if (selectedDate && selectedDate.date) {
			const initialStartDate = moment(selectedDate.date);
			const initialEndDate = moment(selectedDate.date).add(1, 'hour');
			setSelectedDatePickerDate([
				initialStartDate,
				initialEndDate,
			]);

			setFormData({
				...formData,
				appointment_date: [
					initialStartDate.toDate(),
					initialEndDate.toDate()
				]
			});
		}
	}, [selectedDate]);

	useEffect(() => {
		if (selectedDatePickerDate) {
			const [startDate, endDate] = selectedDatePickerDate;
			setFormData({
				...formData,
				appointment_date: [
					startDate.toDate(),
					endDate.toDate()
				]
			});

		}
	}, [selectedDatePickerDate]);

	const handleDatePickerChange = (dates) => {
		if (dates && dates.length === 2) {
			const [startDate, endDate] = dates;
			const newEndDate = moment(startDate).add(1, 'hour');

			setSelectedDatePickerDate([startDate, newEndDate]);
			setFormData({
				...formData,
				appointment_date: [
					startDate.toDate(),
					newEndDate.toDate()
				]
			});
		} else {
			setSelectedDatePickerDate(null);
			setFormData({
				...formData,
				appointment_date: null,
			});
		}
	};

	const resetForm = () => {
		setFormKey((prevKey) => prevKey + 1);
	};
	const initialValues = useMemo(() => ({
		title: "",
		nom: "",
		prenom: "",
		nom_ste: "",
		postal: "",
		adresse: "",
		porte: "",
		tva: "",
		tel: "",
		gsm: "",
		fournisseur: "",
		nbr_comp_elect: "",
		nbr_comp_gaz: "",
		tarification: null,
		commentaire: "",
		note: "",
		appointment_date: selectedDate
			? [
				new Date(selectedDate.date),
				new Date(selectedDate.date.getTime() + 3600000),
			]
			: null,
	}), [selectedDate]);

	useEffect(() => {
		if (secondCardRef.current) {
			const height = secondCardRef.current.clientHeight;
			setSecondCardHeight(height);
		}
	}, [formData.pro]);

	const handleClick = async () => {
		if (isRunning) return;
		try {
			setIsRunning(true);
			setLoading(true);

			if (!selectedDatePickerDate || selectedDatePickerDate.length !== 2) {
				setLoading(false);
				setIsRunning(false);
				return;
			}
			console.log('selectedDate.agendaId', selectedDate.agendaId)

			const response = await axiosClient.get(`rdvs/getAppointmentsByAgenda/${selectedDate.agendaId}`);
			console.log('response', response.data)
			const appointments = response.data;
			const [startDate, endDate] = formData.appointment_date;
			console.log('formData.appointment_date', formData.appointment_date)
			const isOverlap = appointments.some(appointment => {
				const appStartDate = new Date(appointment.start_date);
				const appEndDate = new Date(appointment.end_date);
				console.log('appStartDate', appStartDate)
				console.log('appEndDate', appEndDate)

				return (
					(startDate < appEndDate && endDate > appStartDate) ||
					(appStartDate < endDate && appEndDate > startDate)
				);
			});
			console.log('isOverlap', isOverlap)
			if (isOverlap) {
				setShowAlertAddRdv(true);
				setLoading(false);
				setIsRunning(false);
			} else {
				handleFormSubmit();
			}
		} catch (error) {
			setIsRunning(false);
			console.error("Error checking appointment conflicts:", error);
		}
	};

	const defaultFormData = {
		title: "",
		nom: "",
		prenom: "",
		nom_ste: "",
		postal: "",
		adresse: "",
		porte: "",
		tva: "",
		tel: "",
		gsm: "",
		fournisseur: "",
		pro: null,
		nbr_comp_elect: "",
		nbr_comp_gaz: "",
		ppv: null,
		tarif: null,
		haute_tension: null,
		tarification: null,
		commentaire: "",
		note: "",
		appointment_date: null,
	};
	const handleFormSubmit = async () => {
		if (isRunning) {
			return;
		}

		setIsRunning(true);
		setLoading(true);

		try {
			if (!selectedDatePickerDate || selectedDatePickerDate.length !== 2) {
				// Handle case when appointment date is not selected
				setIsRunning(false);
				setLoading(false);
				return;
			}
			const [nom, ...prenomArray] = formData.nom_prenom ? formData.nom_prenom.split(' ') : ['', ''];
			const prenom = prenomArray.join(' ');

			const [startDate, endDate] = formData.appointment_date;
			const startDateFormatted = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
			const endDateFormatted = moment(endDate).format('YYYY-MM-DD HH:mm:ss');

			const { pro, ppv, tarif, tarification, haute_tension } = formData;
			if (pro == null ||
				ppv == null ||
				tarif == null ||
				tarification == null ||
				haute_tension === null) {
					setLoading(false);
				console.log('pro', pro)
				Modal.warning({
					title: 'Erreur de Validation',
					content: 'Veuillez vous assurer que tous les champs obligatoires sont cochés avant de soumettre.',

					onOk() {
						setIsRunning(false);
						setLoading(false);
					}
				});
				return;
			}


			const formDataToSend = {
				...formData,
				nom: nom,
				prenom: prenom,
				start_date: startDateFormatted,
				end_date: endDateFormatted,
				id_agent: userId,
				id_agenda: selectedDate.agendaId,
				tarification: formData.tarification == 1 ? "Variable" : "Fixe",
				note: formData.note,
				commentaire: formData.commentaire,
				bloquer: false,
				rdv_prive: false,
				status: "encours",

			};

			console.log('formData add rdv', formData)

			console.log('formdataToSend add rdv', formDataToSend)
			const submissionResponse = await axiosClient.post(
				"rdvs",
				formDataToSend
			);
			console.log('submissionResponse add rdv', submissionResponse.data)


			const newAppointment = {
				...submissionResponse.data,
				id: submissionResponse.data.rdv.id,
			};
			setFormData(defaultFormData);
			resetForm();
			setLoading(false);
			setIsRunning(false);

			// onFormSubmit({ ...submissionResponse.data, newAppointment });
			setShowAddModal(false)
			message.success("Rendez-vous ajouté avec succès !");
			setShowAlertAddRdv(false);
		} catch (error) {
			setLoading(false);
			setIsRunning(false);
			console.error("Error adding appointment:", error);

		}
	};

	

	return (
		<Form
			layout="vertical"
			onFinish={handleFormSubmit}
			key={formKey}
			initialValues={initialValues}
		>
			{showAlertAddRdv && (
				<Alert
					message="L'heure sélectionnée est déjà réservée."
					type="warning"
					showIcon
					closable
					onClose={() => setShowAlertAddRdv(false)}
				/>
			)}
			<Card style={{ marginBottom: "10px" }}>
				<Row gutter={[16, 16]} justify="space-between" align="middle">
					<Col md={18} sm={24}>
						<ConfigProvider locale={frFR}>
							<DatePicker.RangePicker
								rules={[
									{
										required: true,
										message: "Veuillez sélectionner une date de rendez-vous !",
									},
								]}
								clearIcon={null}
								value={selectedDatePickerDate}
								showTime={{
									format: "HH:mm",
									minuteStep: 15,
									disabledHours: () => {
										const disabledHours = [];
										for (let i = 0; i < 9; i++) {
											disabledHours.push(i);
										}
										for (let i = 19; i < 24; i++) {
											disabledHours.push(i);
										}
										return disabledHours;
									},
									disabledMinutes: (selectedHour) => {
										if (selectedDatePickerDate && selectedDatePickerDate[0]) {
											const selectedStartHour = selectedDatePickerDate[0].hour();
											if (selectedHour == selectedStartHour) {
												// Disable minutes after 30 minutes from the start hour
												return [];
											}
										}
										return [...Array(31).keys()].slice(30);
									},

								}}
								format="YYYY-MM-DD HH:mm"
								onChange={handleDatePickerChange}
								disabledDate={(current) => current && current < moment().startOf("day")}
								disabled={[false, true]}
							/>
						</ConfigProvider>
					</Col>
					<Col md={6} lg={4} sm={24}>
						<SaveButton
							onClick={handleClick}
							loading={loading}
							buttonText="Enregistrer"
						/>
					</Col>
				</Row>
			</Card>
			<Row gutter={[16, 16]}>
				<Col lg={16} md={16} sm={24}>
					<Card ref={secondCardRef}>
						<Row gutter={[16, 16]}>
							<Col span={24}>
								<Row gutter={[30, 30]}>
									<Col span={12} md={11} sm={24}>
										<Form.Item
											label="Êtes-vous un professionnel ?"
											name="pro"
											rules={[
												{
													required: true,
													message: "Veuillez sélectionner votre statut professionnel !",
												},
											]}
										>
											<Radio.Group
												onChange={(e) =>
													setFormData({
														...formData,
														pro: e.target.value,
													})
												}
											>
												<Radio value={true}>Oui</Radio>
												<Radio value={false}>Non</Radio>
											</Radio.Group>
										</Form.Item>
									</Col>
									<Col lg={12} md={11} sm={24}>
										<Form.Item
											label="Nom et Prénom"
											name="nom_prenom"
											rules={[
												{
													required: true,
													message: "Veuillez entrer votre nom et prénom !",
												},
											]}
										>
											<Input
												onChange={(e) =>
													setFormData({
														...formData,
														nom_prenom: e.target.value,
													})
												}
											/>
										</Form.Item>
									</Col>

								</Row>
							</Col>
							<Col span={24}>
								<Row gutter={[16, 16]}>
									{formData.pro && (
										<>
											<Col span={24}>
												<Row gutter={[30, 30]}>
													<Col lg={11} md={11} sm={24}>
														<Form.Item
															label="Nom de Société"
															name="nom_ste"

														>
															<Input
																onChange={(e) =>
																	setFormData({
																		...formData,
																		nom_ste: e.target.value,
																	})
																}
															/>
														</Form.Item>
													</Col>
													<Col lg={11} md={11} sm={24}>
														<Form.Item
															label="TVA"
															name="tva"
															rules={[

																{
																	validator: (_, value) => {
																		const regex = /^[1-9]\d{8}$/;
																		if (!value) {
																			return Promise.reject('Le numéro de TVA est requis.');
																		}
																		if (!regex.test(value)) {
																			if (value.startsWith('0')) {
																				return Promise.reject('Le numéro de TVA ne peut pas commencer par 0.');
																			} else if (value.length !== 11) {
																				return Promise.reject('Le numéro de TVA doit commencer par BE0 suivi de 9 chiffres.');
																			}
																		}
																		return Promise.resolve();
																	},

																},
															]}
														>
															<Input
																prefix="BE  0"
																maxLength={9}
																onChange={(e) => {
																	const { value } = e.target;
																	const numericValue = value.replace(/\D/g, '');
																	if (numericValue.length <= 9) {
																		setFormData({
																			...formData,
																			tva: value,
																		});
																	}
																}}
															/>
														</Form.Item>
													</Col>
												</Row>
											</Col>
										</>
									)}
								</Row>
							</Col>
							<Col span={24}>
								<Row gutter={[30, 30]}>
									<Col lg={23} md={11} sm={24}>
										<Form.Item
											label="Rue"
											name="adresse"
											rules={[
												{
													required: true,
													message:
														"Veuillez entrer votre adresse !",
												},
											]}
										>
											<Input
												onChange={(e) =>
													setFormData({
														...formData,
														adresse: e.target.value,
													})
												}
											/>
										</Form.Item>

									</Col>
									<Col lg={12} md={11} sm={24}>
										<Form.Item
											label="Porte"
											name="porte"
											rules={[
												{
													required: true,
													message: "Veuillez entrer votre porte !",
												},
												// {
												// 	pattern: /^[0-9]*$/,
												// 	message: "Veuillez entrer uniquement des chiffres.",
												// },
											]}
										>
											<Input
												onChange={(e) =>
													setFormData({
														...formData,
														porte: e.target.value,
													})
												}
											/>
										</Form.Item>
									</Col>

									<Col lg={11} md={11} sm={24}>
										<Form.Item
											label="Code Postal"
											name="postal"
											rules={[
												{
													required: true,
													message:
														"Veuillez entrer votre code postal !",
												},
												{
													pattern: /^\d{4}$/,
													message:
														"Veuillez entrer un code postal valide (4 chiffres).",
												},
											]}
										>
											<Input
											maxLength={4}
												onChange={(e) =>
													setFormData({
														...formData,
														postal: e.target.value,
													})
												}
											/>
										</Form.Item>
									</Col>
								</Row>
							</Col>
							<Col span={24}>
								<Row gutter={[30, 30]}>
									<Col lg={11} md={11} sm={24}>
										<Form.Item
											name="tel"
											label="Téléphone"
											rules={[
												{
													required: true,
													message:
														"Veuillez saisir votre numéro de téléphone!",
												},
												{
													pattern: /^\d{8}$/,
													message:
														"Veuillez saisir un numéro de téléphone valide de 8 chiffres (ex: +32123456789).",
												},
											]}
										>
											<Input
												maxLength={8}
												prefix={
													<span
														style={{
															padding: "0 8px",
														}}
													>
														+32
													</span>
												}
												style={{ width: "100%" }}
												defaultValue=""
												onChange={(e) =>
													setFormData({
														...formData,
														tel: e.target.value,
													})
												}
											/>
										</Form.Item>
									</Col>
									<Col lg={12} md={11} sm={24}>
										<Form.Item
											label="GSM"
											name="gsm"
											rules={[
												{
													pattern: /^\d{8}$/,
													message:
														"Veuillez saisir un numéro de GSM valide de 8 chiffres(ex: +32412345678).",
												},
											]}
										>
											<Input
												maxLength={8}
												prefix={
													<span
														style={{
															padding: "0 8px",
														}}
													>
														+324
													</span>
												}
												style={{ width: "100%" }}
												defaultValue=""
												onChange={(e) =>
													setFormData({
														...formData,
														gsm: e.target.value,
													})
												}
											/>
										</Form.Item>
									</Col>
								</Row>
							</Col>
							<Col span={24}>
								<Row gutter={[30, 30]}>
									<Col lg={11} md={11} sm={24}>
										<Form.Item
											label="Nombre de Compteurs Élect"
											name="nbr_comp_elect"
											rules={[
												{
													required: true,
													message:
														"Veuillez sélectionner le nombre de compteurs Électriques !",
												},
											]}
										>
											<Select
												onChange={(value) =>
													setFormData({
														...formData,
														nbr_comp_elect: value,
													})
												}
												placeholder="Sélectionner le nombre de compteurs électriques"
											>
												<Select.Option value="0">
													0
												</Select.Option>
												<Select.Option value="1">
													1
												</Select.Option>
												<Select.Option value="2">
													2
												</Select.Option>
												<Select.Option value="3">
													3
												</Select.Option>
												<Select.Option value="4">
													4
												</Select.Option>
												<Select.Option value="+4">
													+4
												</Select.Option>
											</Select>
										</Form.Item>
									</Col>
									<Col lg={12} md={11} sm={24}>
										<Form.Item
											label="Nombre de Compteurs Gaz"
											name="nbr_comp_gaz"
											rules={[
												{
													required: true,
													message:
														"Veuillez sélectionner le nombre de compteurs de gaz !",
												},
											]}
										>
											<Select
												onChange={(value) =>
													setFormData({
														...formData,
														nbr_comp_gaz: value,
													})
												}
												placeholder="Sélectionner le nombre de compteurs gaz"
											>
												<Select.Option value="0">
													0
												</Select.Option>
												<Select.Option value="1">
													1
												</Select.Option>
												<Select.Option value="2">
													2
												</Select.Option>
												<Select.Option value="3">
													3
												</Select.Option>
												<Select.Option value="4">
													4
												</Select.Option>
												<Select.Option value="+4">
													+4
												</Select.Option>
											</Select>
										</Form.Item>
									</Col>
									<Col lg={23} md={22} sm={20}>
										<Form.Item
											label="Commentaire"
											name="commentaire"
										>
											<Input.TextArea rows={3} onChange={(e) =>
												setFormData({
													...formData,
													commentaire: e.target.value,
												})} />
										</Form.Item>
									</Col>
								</Row>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col lg={8} md={8} sm={24}>
					<Card style={{ height: formData.pro ? 'auto' : secondCardHeight }}>
						<Row gutter={[16, 16]}>
							<Col span={24}>
								<Form.Item
									name="fournisseur"
									label="Fournisseur"
									rules={[
										{
											required: true,
											message:
												"Veuillez sélectionner votre fournisseur actuel!",
										},
									]}
								>
									<Select
										placeholder="Sélectionner votre fournisseur"
										onChange={(value) =>
											setFormData({
												...formData,
												fournisseur: value,
											})
										}
									>
										{[
											"Luminus",
											"Mega",
											"OCTA+",
											"Eneco",
											"TotalEnergies",
											"Aspiravi Energy",
											"Bolt",
											"COCITER",
											"DATS 24",
											"EBEM",
											"Ecopower",
											"Elegant",
											"Energie.be",
											"Frank Energie",
											"Trevion",
											"Wind voor A",
											"Autre"
										].map((fournisseur, index) => (
											<Option
												key={index}
												value={fournisseur}
											>
												{fournisseur}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									label="PPV"
									name="ppv"
									rules={[
										{
											required: true,
											message:
												"Veuillez sélectionner Oui ou Non pour PPV !",
										},
									]}
								>
									<Radio.Group
										onChange={(e) =>
											setFormData({
												...formData,
												ppv: e.target.value,
											})
										}
									>
										<Radio value={true}>Oui</Radio>
										<Radio value={false}>Non</Radio>
									</Radio.Group>
								</Form.Item>

								{showAdditionalInput && (
									<Row>
										<Col span={24}>
											<Form.Item
												label="Additional Input"
												name="additionalInput"
												rules={[{ required: true }]}
											>
												<Input />
											</Form.Item>
										</Col>
									</Row>
								)}
							</Col>
							<Col span={24}>
								<Form.Item
									label="Tarif Social"
									name="tarif"
									rules={[
										{
											required: true,
											message:
												"Veuillez sélectionner si vous avez un tarif social ou non !",
										},
									]}
								>
									<Radio.Group
										value={formData.tarif}
										onChange={(e) => {
											setFormData({
												...formData,
												tarif: e.target.value,
											});
										}}

									>
										<Radio value={true}>Oui</Radio>
										<Radio value={false}>Non</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									label="Haute Tension"
									name="haute_tension"
									rules={[
										{
											required: true,
											message:
												"Veuillez sélectionner si vous êtes en haute tension ou non !",
										},
									]}
								>
									<Radio.Group
										value={formData.haute_tension}
										onChange={(e) => {
											setFormData({
												...formData,
												haute_tension: e.target.value,
											});
										}}
									>
										<Radio value={true}>Oui</Radio>
										<Radio value={false}>Non</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									label="Tarification"
									name="tarification"
									rules={[
										{
											required: true,
											message:
												"Veuillez sélectionner votre type de tarification !",
										},
									]}
								>
									<Radio.Group
										value={formData.tarification}
										onChange={(e) => {
											setFormData({
												...formData,
												tarification: e.target.value,
											});
										}}
									>
										<Radio value={true}>Variable</Radio>
										<Radio value={false}>Fixe</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item label="Note" name="note" >
									<Input.TextArea rows={3} onChange={(e) =>
										setFormData({
											...formData,
											note: e.target.value,
										})
									} />
								</Form.Item>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</Form>
	);
};

export default AddAppointment;
