import React from 'react';
import { Modal, Descriptions, Button,Card } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const DeletedAppointmentDetails = ({ appointment, visible, onClose, onDownload }) => {
	if (!appointment) return null;

	const {
		start_date,
		end_date,
		deleted_at,
		user,
		agenda,
		nom,
		prenom,
		pro,
		nom_ste,
		tva,
		tel,
		gsm,
		postal,
		adresse,
		porte,
		fournisseur,
		tarification,
		nbr_comp_elect,
		nbr_comp_gaz,
		ppv,
		tarif,
		haute_tension,
		commentaire,
		note,
		deleted_by,
		created_at,
		raison_de_suppression
	} = appointment;

	const dateDeRendezVous = `${start_date} - ${end_date}`;
	const userNomPrenom = user ? `${user.nom || ''} ${user.prenom || ''}` : 'Inconnu';
	const agendaNomPrenom = agenda ? `${agenda.user.nom || ''} ${agenda.user.prenom || ''}` : 'Inconnu';
	const deletedByNomPrenom = deleted_by ? `${deleted_by.nom || ''} ${deleted_by.prenom || ''}` : 'Inconnu';

	return (
		<Modal
			title="Détails du Rendez-vous"
			visible={visible}
			onCancel={onClose}
			width={800}
			footer={[
				<Button key="download" type="primary" icon={<DownloadOutlined />} onClick={() => onDownload(appointment)}>
					Télécharger
				</Button>,
				<Button key="back" onClick={onClose}>
					Fermer
				</Button>,
			]}
		>
			<Card
						title="Raison de suppression"
						size="small"
						bordered={false}
						className="carddisplayrdvnote"
						style={{ backgroundColor: "#FF4D4F", opacity: 0.8 }} 
					>
						<p>{raison_de_suppression || "Aucune raison de suppression"}</p>
					</Card>
			<Descriptions bordered column={2} labelStyle={{ color: '#61559F' }}>
				
				<Descriptions.Item label="Date de rendez-vous" span={2}>
					{dateDeRendezVous}
				</Descriptions.Item>
				{deleted_at != "Invalid date" && (
					<Descriptions.Item label="Date de suppression" span={2} labelStyle={{ color: '#61559F' }}>
						{deleted_at}
					</Descriptions.Item>
				)}
				<Descriptions.Item label="Supprimé par" span={2} labelStyle={{ color: '#61559F' }}>
					{deletedByNomPrenom}
				</Descriptions.Item >
				<Descriptions.Item label="Agent" labelStyle={{ color: '#61559F' }}>
					{userNomPrenom}
				</Descriptions.Item>
				<Descriptions.Item label="Agenda" labelStyle={{ color: '#61559F' }}>
					{agendaNomPrenom}
				</Descriptions.Item>
				<Descriptions.Item label="Client" labelStyle={{ color: '#61559F' }}>
					{prenom ? `${nom || ''} ${prenom || ''}` : nom || ''}
				</Descriptions.Item>
				<Descriptions.Item label="Type" labelStyle={{ color: '#61559F' }}>
					{pro == 1 ? 'Professionnel' : 'Residentiel'}
				</Descriptions.Item>
				<Descriptions.Item label="Societé" labelStyle={{ color: '#61559F' }}>
					{nom_ste || 'N/A'}
				</Descriptions.Item>
				<Descriptions.Item label="TVA" labelStyle={{ color: '#61559F' }}>
					{tva || 'N/A'}
				</Descriptions.Item>
				<Descriptions.Item label="Rue" span={2} labelStyle={{ color: '#61559F' }}>
					{adresse}
				</Descriptions.Item>
				<Descriptions.Item label="Porte" labelStyle={{ color: '#61559F' }}>
					{porte}
				</Descriptions.Item>
				<Descriptions.Item label="Postal" labelStyle={{ color: '#61559F' }}>
					{postal}
				</Descriptions.Item>
				<Descriptions.Item label="TEL" labelStyle={{ color: '#61559F' }}>
					{tel ? `+32 ${tel}` : 'N/A'}
				</Descriptions.Item>
				<Descriptions.Item label="GSM" labelStyle={{ color: '#61559F' }}>
					{gsm ? `+324 ${gsm}` : 'N/A'}
				</Descriptions.Item>
				<Descriptions.Item label="Compteurs électriques" labelStyle={{ color: '#61559F' }}>
					{nbr_comp_elect}
				</Descriptions.Item>
				<Descriptions.Item label="Compteurs gazs" labelStyle={{ color: '#61559F' }}>
					{nbr_comp_gaz}
				</Descriptions.Item>
				<Descriptions.Item label="Fournisseur" span={2} labelStyle={{ color: '#61559F' }}>
					{fournisseur}
				</Descriptions.Item>
				<Descriptions.Item label="PPV" labelStyle={{ color: '#61559F' }}>
					<span style={{ color: ppv ? '#4FE572' : '#FF0000' }}>
						{ppv == 1 ? "oui" : "non"}
					</span>
				</Descriptions.Item>
				<Descriptions.Item label="Tarif social" labelStyle={{ color: '#61559F' }}>
					<span style={{ color: tarif ? '#4FE572' : '#FF0000' }}>
						{tarif == 1 ? "oui" : "non"}
					</span>
				</Descriptions.Item>
				<Descriptions.Item label="Haute Tension" labelStyle={{ color: '#61559F' }}>
					<span style={{ color: haute_tension ? '#4FE572' : '#FF0000' }}>
						{haute_tension == 1 ? "oui" : "non"}
					</span>
				</Descriptions.Item>
				<Descriptions.Item label="Tarification" labelStyle={{ color: '#61559F' }}>
					{tarification}
				</Descriptions.Item>
				<Descriptions.Item label="Commentaire" span={2} labelStyle={{ color: '#61559F' }}>
					{commentaire || 'N/A'}
				</Descriptions.Item>
				<Descriptions.Item label="Note" span={2} labelStyle={{ color: '#61559F' }}>
					{note || 'N/A'}
				</Descriptions.Item>
			</Descriptions>
		</Modal>
	);
};

export default DeletedAppointmentDetails;
