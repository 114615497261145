/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Card, Checkbox, Empty } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { handleCheckboxClick, handleSelectAll } from "../services/api";
import { useCalendar } from "../../../CalendarContext";
import { fetchAgentCommercialUsers } from "../../caledrier/services/api";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { pusherKey } from "../../../api/axios";
window.Pusher = Pusher;

window.Echo = new Echo({
	broadcaster: 'pusher',
	key: pusherKey,
	cluster: 'eu',
	encrypted: true,
});

const ContactList = ({
	selectedItems,
	setSelectedItems,
	agendas,
	setAgentId,
	setAgendaId,
	role,
	setContactAgendas,
}) => {
	const [agentSelectedItemId, setAgentSelectedItemId] = useState(null);
	const [selectAllChecked, setSelectAllChecked] = useState(false);
	const { agentCommercialUsers, setAgentCommercialUsers } = useCalendar();
	console.log('role', role)

	useEffect(() => {
		fetchAgentCommercialUsers(setAgentCommercialUsers, role);
	}, [setAgentCommercialUsers, role]);

	useEffect(() => {
		// Initialize Pusher
		console.log('Initializing Pusher...');
		const pusher = new Pusher(pusherKey, {
			cluster: 'eu',
			encrypted: true,
			logToConsole: true,
		});

		// Subscribe to the agenda channel
		console.log('Subscribing to agenda channel...');
		const channel = pusher.subscribe('agenda-channel');

		const handleAgendaUpdated = (data) => {
			console.log('Agenda updated event received:', data);

			const updatedAgendaId = data.agenda.id;
			const contactIdToUpdate = data.agenda.contact_id;
			const isDisabled = data.agenda.isDisabled;
			const userDetails = data.agenda.user;
			console.log('Updated agenda ID:', updatedAgendaId);
			console.log('Contact ID to update:', contactIdToUpdate);
			console.log('Is Disabled:', isDisabled);

			setAgentCommercialUsers((prevUsers) => {
				console.log('Previous agentCommercialUsers:', prevUsers);

				if (isDisabled == 1) {
					// Remove user from the array
					const filteredUsers = prevUsers.filter((user) => user.id != contactIdToUpdate);
					console.log('Filtered agentCommercialUsers (after removal):', filteredUsers);
					return filteredUsers;
				} else if (isDisabled == 0) {
					const existingUser = prevUsers.find((user) => user.id == contactIdToUpdate);
					if (existingUser) {
						console.log('User is already present in the array:', existingUser);
						return prevUsers;
					} else {
						const newUser = {
							id: contactIdToUpdate,
							nom: userDetails.nom,
							prenom: userDetails.prenom,

						};
						const updatedUsers = [...prevUsers, newUser];
						console.log('Updated agentCommercialUsers (after addition):', updatedUsers);
						return updatedUsers;
					}
				}

				// If isDisabled is neither 0 nor 1, return the previous state
				return prevUsers;
			});

			setSelectedItems((prevSelectedItems) => {
				const updatedSelectedItems = prevSelectedItems.filter(
					(itemId) => !(itemId == contactIdToUpdate && isDisabled == 1)
				);
				console.log('Updated selectedItems:', updatedSelectedItems);
				return updatedSelectedItems;
			});

			setContactAgendas((prevAgendas) => {
				const updatedAgendas = prevAgendas.filter((agenda) => agenda.id != updatedAgendaId);
				console.log('Updated agendas:', updatedAgendas);
				return updatedAgendas;
			});
		};

		channel.bind('agenda.updated', handleAgendaUpdated);
		return () => {
			console.log('Unsubscribing from agenda channel...');
			channel.unbind('agenda.updated', handleAgendaUpdated);
			pusher.unsubscribe('agenda-channel');
			pusher.disconnect();
		};
	}, [setAgentCommercialUsers, setSelectedItems, setContactAgendas, agentCommercialUsers]);

	useEffect(() => {
		if (agendas.length > 0) {
			setAgendaId(agendas[0].id);
		}
	}, [agendas, setAgendaId]);

	const handleCheckboxClickWrapper = (userId) => {
		handleCheckboxClick(
			userId,
			role,
			agentSelectedItemId,
			setAgentSelectedItemId,
			setAgentId,
			selectedItems,
			setSelectedItems,
			setContactAgendas
		);
	};

	const handleSelectAllWrapper = () => {
		handleSelectAll(
			selectAllChecked,
			agentCommercialUsers,
			setSelectedItems,
			setSelectAllChecked,
			setContactAgendas
		);
	};

	const capitalizeFirstLetter = (string) => {
		if (typeof string != 'string') {
			console.error('Expected a string, but got:', typeof string);
			return '';
		}
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	return (
		<Card title="Contacts" style={{ width: "18%" }}>
			{agentCommercialUsers?.length == 0 ? (
				<Empty
					image={<UserOutlined style={{ fontSize: 48 }} />}
					description="No data"
				/>
			) : (
				<Checkbox.Group value={selectedItems}>
					{(role === "Superviseur" || role === "Admin") && (
						<Checkbox
							key="select-all-checkbox"
							onChange={handleSelectAllWrapper}
							checked={selectAllChecked}
							style={{ marginBottom: "10px", fontWeight: 600 }}
						>
							Sélectionner Tout
						</Checkbox>
					)}
					{agentCommercialUsers?.map((user) => (
						<Checkbox
							key={user?.id}
							value={user?.id}
							checked={
								role === "agent"
									? agentSelectedItemId == user?.id
									: selectedItems.includes(user?.id)
							}
							onClick={() => handleCheckboxClickWrapper(user?.id)}
							style={{ margin: 0 }}
							disabled={
								role === "agent" &&
								agentSelectedItemId != user?.id &&
								agentSelectedItemId != null
							}
						>
							{capitalizeFirstLetter(user?.prenom)}{" "}
							{capitalizeFirstLetter(user?.nom)}
						</Checkbox>
					))}
				</Checkbox.Group>
			)}
		</Card>
	);
};

export default ContactList;
