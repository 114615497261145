import React, { useState, useEffect } from 'react';
import { Card, Select, Spin, Empty, Form, Typography } from 'antd';
import Chart from 'react-apexcharts';
import { fetchAgents, fetchAppointments2 } from '../services/api';
import moment from 'moment';

const { Title } = Typography;
const { Option } = Select;

const AppointmentsChart = () => {
    const [agents, setAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [month, setMonth] = useState(moment().month() + 1); // Months are 0-indexed in moment.js
    const [year, setYear] = useState(moment().year());
    const [appointmentsData, setAppointmentsData] = useState({ dates: [], counts: [] });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getAgents = async () => {
            try {
                const agents = await fetchAgents();
                setAgents(agents);
            } catch (error) {
                console.error('Error fetching agents:', error);
            }
        };
        getAgents();
    }, []);

    useEffect(() => {
        const getAppointments = async () => {
            if (selectedAgent) {
                try {
                    setLoading(true);
                    const data = await fetchAppointments2(selectedAgent, month, year);
                    console.log('data', data);

                    // Adjust appointments data to match dates for the selected month and year
                    const dates = generateDatesForSelectedMonth();
                    const counts = dates.map(date => {
                        const appointmentCount = data.dates.indexOf(date) > -1 ? data.counts[data.dates.indexOf(date)] : 0;
                        return appointmentCount;
                    });

                    setAppointmentsData({ dates, counts });
                } catch (error) {
                    console.error('Error fetching appointments:', error);
                } finally {
                    setLoading(false);
                }
            }
        };
        getAppointments();
    }, [selectedAgent, month, year]);

    const generateDatesForSelectedMonth = () => {
        const start = moment().year(year).month(month - 1).startOf('month'); // Correctly set month and year
        const end = moment().year(year).month(month - 1).endOf('month'); // End of the month
        const dates = [];
        
        for (let m = start; m.isBefore(end) || m.isSame(end); m.add(1, 'days')) {
            dates.push(m.format('YYYY-MM-DD')); // Format as needed
        }

        return dates;
    };

    const chartOptions = {
        chart: {
            id: 'appointments-chart',
            type: 'bar',
        },
        xaxis: {
            categories: appointmentsData.dates, 
            tickAmount: appointmentsData.dates.length,
            labels: {
                formatter: function (value) {
                    return value;
                },
            },
        },
        yaxis: {
            min: 0,
            forceNiceScale: true,
            labels: {
                formatter: (value) => Math.floor(value) === value ? value : '',
            },
        },
    };

    const chartSeries = [
        {
            name: 'Rendez-vous',
            data: appointmentsData.counts,
        },
    ];

    return (
        <Card>
            <Title level={4}>Production des agents</Title>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                <Form layout="inline" style={{ flexGrow: 1 }}>
									
                    <Form.Item label="Choisir un Agent" style={{ marginRight: 20, fontWeight: "600" }}>
                        <Select
                            placeholder="Choisir un Agent"
                            style={{ width: 200 }}
                            onChange={(value) => setSelectedAgent(value)}
                            showSearch
                            allowClear
                            filterOption={(inputValue, option) =>
                                option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                            }
                        >
                            {agents.map(agent => (
                                <Option key={agent.id} value={agent.id}>
                                    {`${agent.nom} ${agent.prenom}`}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Mois" style={{ marginRight: 20 }}>
                        <Select
                            value={month}
                            onChange={value => setMonth(value)}
														style={{ width: 250 }}
                        >
                            {Array.from({ length: 12 }, (_, index) => (
                                <Option key={index + 1} value={index + 1}>
                                    {moment().month(index).format('MMMM')}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Année" style={{ marginRight: 20 }}>
                        <Select
                            value={year}
                            onChange={value => setYear(value)}
                            style={{ width: 250 }}
                        >
                            {Array.from({ length: 10 }, (_, index) => (
                                <Option key={year - index} value={year - index}>
                                    {year - index}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </div>
            {loading ? (
                <Spin />
            ) : (
                selectedAgent ? (
                    <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="bar"
                        height={400}
                        width="100%"
                    />
                ) : (
                    <Empty description="Veuillez choisir un agent." />
                )
            )}
        </Card>
    );
};

export default AppointmentsChart;
