import React, { useState, useEffect } from 'react';
import { Avatar } from 'antd';
import profilePic from '../../../assets/images/profile-user.png';

const ImageLoader = ({ src, alt }) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.onload = () => setImageLoaded(true);
        img.onerror = () => setImageLoaded(false);
        img.src = src;
    }, [src]);

    return <Avatar src={imageLoaded == true || imageLoaded == 1 || imageLoaded ? src : profilePic} alt={alt} />;
};

export default ImageLoader;
