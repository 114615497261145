import React, { useEffect, useState } from "react";
import {
	Modal,
	Form,
	Input,
	Select,
	Button,
	Row,
	Col,
	Avatar,
	Card,
	Switch,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { handleUpdateUser } from "../services/api";
import SaveButton from "../../../constants/SaveButton";
import { baseURL2 } from "../../../api/axios";

const { Option } = Select;

const UpdateUserModal = ({ visible, onCancel, onUpdate, userData }) => {
	const [form] = Form.useForm();
	const [imageFile, setImageFile] = useState(null);
	const [imagePreview, setImagePreview] = useState('');

	useEffect(() => {
		if (userData) {
			form.setFieldsValue({
				...userData,
				isActive: userData.isActive == 1
			});
			setImagePreview(userData.image ? `${baseURL2}/storage/${userData.image}` : `${baseURL2}/storage/images/default-img.png`);
		}
	}, [form, userData]);

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setImagePreview(reader.result);
			};
			reader.readAsDataURL(file);
			setImageFile(file);
		}
	};

	const handleUpdate = () => {
		handleUpdateUser(userData, form, imageFile, onUpdate, onCancel);
	};

	return (
		<Modal
			title="Modifier Utilisateur"
			width="70%"
			visible={visible}
			onCancel={onCancel}
			footer={[
				<Button
					key="cancel"
					onClick={onCancel}
					icon={<CloseOutlined />}
				>
					Annuler
				</Button>,
				<SaveButton key="submit" onClick={handleUpdate} buttonText="Enregistrer" />,
			]}
		>
			<Card>
				<Form form={form} initialValues={userData} layout="vertical">
					<Row gutter={16}>
						<Col xs={24} sm={24} lg={6}>
							<Avatar
								size={120}
								src={imagePreview}
								shape="square"
								style={{ borderRadius: "8px", width: '80%' }}
							/>
							<Form.Item>
								<div style={{ position: 'relative' }}>
									<input
										type="file"
										onChange={handleFileChange}
										id="fileInput"
										style={{ display: 'none' }}
									/>
									<Button
										type="primary"
										onClick={() => document.getElementById('fileInput').click()}
										style={{ backgroundColor: 'transparent', color: 'black', border: '1px solid black', marginTop: '10px' }}
									>
										Choisir une photo
									</Button>
								</div>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} lg={18}>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										label="Nom"
										name="nom"
										rules={[
											{
												required: true,
												message: "Veuillez entrer le nom!",
											},
										]}
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="Prenom"
										name="prenom"
										rules={[
											{
												required: true,
												message: "Veuillez entrer le prénom!",
											},
										]}
									>
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										label="Email"
										name="email"
										rules={[
											{
												required: true,
												message: "Veuillez entrer votre email!",
											},
										]}
									>
										<Input type="email" placeholder="Email" disabled />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="Rôle"
										name="role"
										rules={[
											{
												required: true,
												message: "Veuillez sélectionner un rôle!",
											},
										]}
									>
										<Select style={{ width: "100%" }}>
											<Option value="Admin">Admin</Option>
											<Option value="Agent">Agent</Option>
											<Option value="Superviseur">Superviseur</Option>
											<Option value="Agent Commercial">Agent Commercial</Option>
											<Option value="Confirmateur">Confirmateur</Option>
										</Select>

									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item label="Mot de passe" name="password">
										<Input.Password placeholder="**********" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="État du compte"
										name="isActive"
										valuePropName="checked"
									>
										<Switch checkedChildren="Actif" unCheckedChildren="Inactif" />
									</Form.Item>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</Card>
		</Modal>
	);
};

export default UpdateUserModal;
